export const errorTranslatedStrings = {
  lengthErrorMessage: maxLength => ({
    id: 'validation.errors.length',
    defaultMessage: ' must be {maxLength} characters or less',
    values: { maxLength: maxLength }
  }),
  oneCharacterLengthErrorMessage: {
    id: 'validation.errors.oneCharacterLength',
    defaultMessage: ' must be 1 character'
  },
  invalidCharactersErrorMessage: {
    id: 'validation.errors.invalidCharacters',
    defaultMessage: ' contains invalid characters'
  },
  zipCodeInvalidCharactersErrorMessage: {
    id: 'validation.errors.zipCodeInvalidCharacters',
    defaultMessage: ' must contain latin characters a-z, A-Z, spaces and numbers 0-9 only'
  },
  requiredErrorMessage: {
    id: 'validation.errors.required',
    defaultMessage: 'Required Field'
  },
  DateBoundsErrorMessage: {
    id: 'validation.errors.dateBounds',
    defaultMessage: ' must be a past date and not earlier than 1900.'
  },
  formatDateErrorMessage: {
    id: 'validation.errors.formatDate',
    defaultMessage: ' must contain day, month and year.'
  },
  yearMonthFormatDateErrorMessage: {
    id: 'validation.errors.yearMonthFormatDate',
    defaultMessage: ' must contain month and year.'
  },
  yearFormatDateErrorMessage: {
    id: 'validation.errors.yearFormatDate',
    defaultMessage: ' must contain year.'
  },
  middleInitialLengthErrorMessage: {
    id: 'validation.errors.middleInitialLength',
    defaultMessage: 'MI must be one letter'
  },
  middleInitialCharactersOnlyErrorMessage: {
    id: 'validation.errors.middleInitialCharactersOnly',
    defaultMessage: 'MI must contain latin characters a-z, A-Z, spaces and numbers 0-9 only'
  }
};
