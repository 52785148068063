import React from "react";
import {Radio, SvgIcon, useTheme} from "@material-ui/core";
import './RadioButton.scss';
import PropTypes from "prop-types";

function RadioIcon(props) {
    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="11.5" stroke="#959595" strokeWidth="0.5" fill="#f3f3f3" />
        </SvgIcon>
    );
}

function RadioCheckedIcon(props) {
    const { color, disabled } = props;
    const palette = useTheme().palette;

    const fill = (color && !disabled) ? palette[color]: palette.primary.dark;

    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="11.5" stroke="#959595" strokeWidth="0.5" fill="#f3f3f3" />
            <circle cx="12" cy="12" r="6" fill={fill}>
                <animate attributeName="r" dur="0.15" values="0; 6" calcMode="spline" keySplines="0.5 0 0.5 1"/>
            </circle>
        </SvgIcon>
    );
}

RadioCheckedIcon.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool
}

export default function RadioButton(props) {
    const {disabled, color} = props;

    return <Radio
        {...props}
        icon={<RadioIcon/>}
        checkedIcon={<RadioCheckedIcon disabled={disabled} color={color}/>}
    />
}
