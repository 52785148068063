// Used with permission from source https://github.com/ShavitCohen/redux-workshop

import createMiddleware from '../../utils/middleware-helper';
import loggerUtils from './loggerUtils';
import utils from '../../../utils/utils';

const logPatientAppVersion = () => {
  const elemVersion = document.getElementsByClassName('version');
  const patientAppVersion = elemVersion.length ? elemVersion[0].innerText : 'Patient-App version missing';
  utils.logMessage('patient app version: ', patientAppVersion);
}
logPatientAppVersion();

const middleware = () => {};
export const nextOverride = async ({ next, getState, action }) => {
  const { payload } = action;
  let { meta } = action;
  meta = meta || { feature: '[SYSTEM]' };
  meta.feature = meta.feature || '[SYSTEM]';
  const patientAppPrefix = '[Patient-App]';

  switch (true) {
    case utils.isStringContainsAny(action.type, loggerUtils.logActions):
      utils.group(`${patientAppPrefix} ${meta.feature} ${action.type}`);

      if (meta.method) {
        utils.logMessage('method: ', meta.method);
      }
      if (meta.url) {
        utils.logMessage('url: ', meta.url);
      }

      if (payload) {
        const payloadId = payload.id || payload.patientId;
        const payloadType = payload.type || payload.patientType;
        if (payloadId) {
          utils.logMessage('payload id: ', payloadId);
        }
        if (payloadType) {
          utils.logMessage('payload type: ', payloadType);
        }
        if (utils.isStringContainsAny(action.type, loggerUtils.responseActions)) {
          utils.logMessage('api response: ', loggerUtils.filterResponse(meta.feature, payload));
        }
      }

      utils.group('CURRENT STATE:');
      utils.log(loggerUtils.filterState(meta.feature, getState()));
      console.groupEnd();

      await next(action);

      utils.group('NEXT STATE:');
      utils.log(loggerUtils.filterState(meta.feature, getState()));
      console.groupEnd();

      console.groupEnd();
      break;
    default:
      next(action);
  }
};

export default createMiddleware({
  nextOverride,
  feature: ''
})(middleware);
