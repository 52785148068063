import { combine } from 'redux-form-validators';
import { patientFormTranslatedStrings } from '../../translations/PatientFormTranslations';
import validators from '../../validation/validators';

const { firstName, lastName, chartNumber, zipCodeErrorName } = patientFormTranslatedStrings;

let maxPatientLastNameLength = 25;

const maxPatientFirstNameLength = 25;
const maxChartNumberLength = 20;
const maxZipCodeLength = 7;

let inlineFormFieldsValidations = {
  firstName: {
    validations: combine(
        validators.requiredError(),
        validators.lengthError(firstName, maxPatientFirstNameLength),
        validators.emojiPatternError(firstName)
    )
  },
  lastName: {
    validations: combine(
        validators.requiredError(),
        validators.lengthError(lastName, maxPatientLastNameLength),
        validators.emojiPatternError(lastName)
    )
  },
  mi: {
    validations: combine(validators.middleInitialLengthError(), validators.middleInitialCharactersOnlyError())
  },
  gender: {
    validations: []
  },
  genderRequired: {
    validations: [validators.requiredError()]
  },
  chartNumber: {
    validations: combine(
        validators.lengthError(chartNumber, maxChartNumberLength),
        validators.emojiPatternError(chartNumber)
    )
  },
  zipCode: {
    validations: combine(
        validators.lengthError(zipCodeErrorName, maxZipCodeLength),
        validators.patientZipCodePatternError(zipCodeErrorName)
    )
  },
  zipCodeRequired: {
    validations: combine(
        validators.requiredError(),
        validators.lengthError(zipCodeErrorName, maxZipCodeLength),
        validators.patientZipCodePatternError(zipCodeErrorName)
    )
  }
};

export const inlineFieldValidations = (fieldName) => inlineFormFieldsValidations[fieldName].validations;

export default inlineFormFieldsValidations;
