import React from 'react';
import classNames from 'classnames';

import styles from './NotificationIcon.module.css';

const NotificationIcon = ({ icon }) => {
  const className = classNames(styles[`${icon.toLowerCase()}`], styles.icon);

  return <div className={className} />;
};

export default NotificationIcon;
