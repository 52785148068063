const ES6Promise = require('es6-promise');
ES6Promise.polyfill();
const axios = require('axios');
export let source = axios.CancelToken.source();
let instance;

export const apiConfig = options => {
  if (source.token.reason && source.token.reason.message === 'canceled') {
    source = axios.CancelToken.source();
  }
  instance = axios.create({
    baseUrl: options.url,
    headers: {
      'Content-Type': 'application/json'
    },
    cancelToken: source.token
  });
  instance.CancelToken = axios.CancelToken;
  instance.isCancel = axios.isCancel;
  return instance;
};

export const send = options => {
  const api = apiConfig(options);
  return api(options);
};
