import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Alert.module.scss';

const Alert = props => {
  const { className: classNameProp, variant, heading, content } = props;

  const className = classNames(styles.root, styles[variant], classNameProp);

  return (
    <div className={className}>
      {heading && <div className={styles.heading}>{heading}</div>}
      {content && <p className={styles.content}>{content}</p>}
      {props.children}
    </div>
  );
};

Alert.propTypes = {
  /**
   * When set, component changes color
   */
  variant: PropTypes.oneOf(['danger', 'warning'])
};

Alert.defaultProps = {
  variant: 'warning'
};

export default Alert;
