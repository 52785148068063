import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import styles from './SearchField.module.scss';
import classNames from 'classnames';

const SearchField = props => {
  const { id, className: classNameProp, name, onChange, placeholder, type, value } = props;
  const className = classNames(styles.input, classNameProp);
  return (
    <Form.Control
      id={id}
      className={className}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
};

SearchField.propTypes = {
  /**
   * Input name
   */
  name: PropTypes.string,
  /**
   * Callback, fired when value changes
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Input type
   */
  type: PropTypes.string,
  /**
   * Input value
   */
  value: PropTypes.string.isRequired
};

SearchField.defaultProps = {
  name: '',
  placeholder: 'Search...',
  type: 'search'
};

export default SearchField;
