import createMiddleware from '../../utils/middleware-helper';
import * as AT from '../../action-types';
import { sdkInit } from '../sdk/sdk.actions';
import {
  getAppConfig,
  setAppConfig,
  changeZipCodeVisibility,
  setUrlParams,
  appReady,
  appError
} from './shell.actions';

import {
  changeChartNumberAvailability,
  validationSettingsLoaded,
  generateInitialChartNumber,
  setSuppressClearPatientFieldsDialog
} from '../../feature/patient-management/patient-management.actions'
import { patientTypeParamName, companyIdUrlParamName, doctorIdUrlParamName } from '../../../consts';
import localStorageUtils from '../../../utils/local-storage';

export const middleware = ({ action, dispatch }) => {
  const { payload, type } = action;

  switch (type) {
    case AT.APP_INIT: {
      const urlParams = payload;
      const sdkRequest = {};

      // patch for undefined patientType, ITEROBIZ-39520
      if (isNaN(Number(urlParams[patientTypeParamName]))) {
        urlParams[patientTypeParamName] = '1';
      }

      localStorageUtils.updateDoctorId(urlParams[doctorIdUrlParamName]);
      
      dispatch(setSuppressClearPatientFieldsDialog(localStorageUtils.getPatientAppConfig().isClearPatientFieldsDialogSuppressed));
      dispatch([
        setUrlParams(urlParams),
        getAppConfig({ companyId: urlParams[companyIdUrlParamName] }), 
        sdkInit({ sdkRequest })
      ]);
      break;
    }

    case AT.GET_APP_CONFIG.SUCCESS: {
      const config = payload.data;
      const actions = [
        setAppConfig(config), 
        changeZipCodeVisibility(config.isZipCodeEnabled),
        validationSettingsLoaded(config.regulatorySettings ? config.regulatorySettings : {})        
      ];
      if (config.regulatorySettings != null) {
        const shouldGenerateChartNumber = config.regulatorySettings.shouldGenerateChartNumber || false;
        actions.push(
          generateInitialChartNumber(shouldGenerateChartNumber),
          changeChartNumberAvailability(config.regulatorySettings.isChartNumberDisabled)          
        );
      }
      actions.push(appReady());
      dispatch(actions);
      break;
    }
    case AT.GET_APP_CONFIG.ERROR: {
      dispatch(appError(AT.GET_APP_CONFIG.API_REQUEST));
      break;
    }
    case AT.SAVE_SUPPRESS_CLEAR_PATIENT_FIELDS_DIALOG:
      localStorageUtils.updatePatientAppConfig({ isClearPatientFieldsDialogSuppressed: true });
      break;
    default:
    // do nothing
  }
};

export default createMiddleware({
  feature: AT.SHELL
})(middleware);