export default class PatientViewModel {
  constructor(formPatient) {
    this.uid = formPatient.uid;
    this.firstName = formPatient.firstName;
    this.lastName = formPatient.lastName;
    this.fullName = formPatient.fullName;
    this.chartNumber = formPatient.chartNumber;
    this.lastScanDate = formPatient.lastScanDate;
    this.dateOfBirth = formPatient.dateOfBirth || undefined;
    this.gender = formPatient.gender;
    this.mi = formPatient.mi || '';
    this.zipCode = formPatient.zipCode;
    this.alignPatientId = formPatient.alignPatientId;
    this.pairings = formPatient.pairings;
    this.type = formPatient.type;
  }
}
