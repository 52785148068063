import React from 'react';
import PropTypes from 'prop-types';
import TranslatedContent from '../Translations/TranslatedContent';
import DateInput from '../UI/DateInput/DateInput';
import TextInput from '../UI/TextInput/TextInput';
import RadioGroup from '../UI/RadioGroup';
import classNames from 'classnames';
import './DynamicInputField.scss';

class DynamicInputField extends React.Component {
  render() {
    let innerJsx = null;
    const { input, meta, formFields, canShowErrorOnLoad, readOnly, maxLength, format } = this.props;
    const { inputType, options, label, name, isRequired, inline, isShowErrorBorder } = formFields;

    const isShowErrorImmediately = !!canShowErrorOnLoad && !!meta.error;
    const forceShowError = isShowErrorImmediately || isShowErrorBorder;

    const labelJsx = (
      <label className="gender-label">
        {<TranslatedContent contentArray={[label]} />}:{isRequired && !readOnly && <span className="required"> *</span>}
      </label>
    );

    switch (inputType) {
      case 'radio-button':
        innerJsx = (
          <div className="gender-input-field">
            {labelJsx}
            <RadioGroup
              options={options}
              label={label}
              name={name}
              readOnly={readOnly}
              {...input}
              {...meta}
              color="secondary"
            />
          </div>
        );
        break;
      case 'text':
        const inputFieldLabel = (
          <>
            <TranslatedContent contentArray={[label]} />:
          </>
        );

        innerJsx = (
          <TextInput
            id={`text-input-${name}`}
            forceShowError={forceShowError}
            {...input}
            readOnly={readOnly}
            {...meta}
            maxLength={maxLength}
            inputLabel={inputFieldLabel}
            required={isRequired}
          />
        );
        break;
      case 'date':
        innerJsx = (
          <DateInput
            forceShowError={forceShowError}
            {...input}
            format={format}
            readOnly={readOnly}
            {...meta}
            inputLabel={[label, ':']}
            id={`date-input-${name}`}
          />
        );
        break;
      default:
        innerJsx = null;
        break;
    }

    return <div className={classNames('patient-form-input-field', { 'inline-element': inline })}>{innerJsx}</div>;
  }
}

DynamicInputField.propTypes = {
  formField: PropTypes.shape({
    inputType: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        label: PropTypes.object,
        value: PropTypes.any
      })
    ),
    label: PropTypes.object,
    name: PropTypes.string,
    inline: PropTypes.bool,
    widthColumns: PropTypes.number
  }),
  input: PropTypes.object
};

export default DynamicInputField;
