import { camelCase, isArray } from 'lodash';
import * as AT from './../../action-types';

const SUCCESS = '.SUCCESS';
const ERROR = '.ERROR';

const logActions = [AT.SET_URL_PARAMS, AT.API_REQUEST, SUCCESS, ERROR];

const responseActions = [SUCCESS, ERROR];

const sanitizePatient = patient => {
    const { uid, type } = patient;
    return { uid, type };
};

const sanitizeSensitiveDataPatientManagmenet = state => {
  return {
    ...state,
    patientManagement: {
      ...state.patientManagement,
      initialPatientData: state.patientManagement.initialPatientData && state.patientManagement.initialPatientData.uid ? 
                          sanitizePatient(state.patientManagement.initialPatientData) : state.patientManagement.initialPatientData,
      conflictedPatient: state.patientManagement.conflictedPatient ? sanitizePatient(state.patientManagement.conflictedPatient) : state.patientManagement.conflictedPatient
    }
  }
};

const sanitizeSensitiveDataPatientSearch = state => {
    return {
        ...state,
        patientSearch: {
            ...state.patientSearch,
            patientsList: state.patientSearch.patientsList ? state.patientSearch.patientsList.map(patient => sanitizePatient(patient)) : [],
            conflictedPatient: state.patientSearch.conflictedPatient ? sanitizePatient(state.patientSearch.conflictedPatient) : state.patientSearch.conflictedPatient
        }
    }
};

const sanitizeSensitivePatientDataApiResponse = patients => {
    if (!isArray(patients)) {
        return;
    }
    return patients.map(patient => sanitizePatient(patient));
};

const hideFormFields = state => {
    return { ...state, patientManagement: { ...state.patientManagement, formFields: 'hidden' } };
};

const filterState = (feature, state) => {
    let newState;
    switch (feature) {
        case AT.PATIENT_MANAGEMENT:
            newState = sanitizeSensitiveDataPatientManagmenet(state);
            newState = hideFormFields(newState);
            break;
        case AT.PATIENT_SEARCH:
            newState = sanitizeSensitiveDataPatientSearch(state);
            break;
        default:
            newState = { ...state };
            break;
    }
    const newFeature = camelCase(feature.replace('[', '').replace(']', ''));
    const newStateFeature = { [newFeature]: newState[newFeature] };
    return newStateFeature;
};

const getSanitzedPatientDataFromApiResponse = data => {
    const { patients, conflictedPatients } = data;
    return {
        patients: sanitizeSensitivePatientDataApiResponse(patients),
        conflictedPatients: sanitizeSensitivePatientDataApiResponse(conflictedPatients)
    };
}

const filterResponse = (feature, payload) => {
    if (!payload) {
        return 'no payload';
    }
    if (payload.data) {
        switch (feature) {
            case AT.PATIENT_MANAGEMENT:
            case AT.PATIENT_SEARCH:
                return { statusCode: payload.data.statusCode, ...getSanitzedPatientDataFromApiResponse(payload.data) };
            default:
                break;
        }
        return payload.data;
    }
    if (payload.error) {
        const { status, message } = payload.error;
        const generalError = { status, message };
        
        if (payload.error.data) {
            const errorData = getSanitzedPatientDataFromApiResponse(payload.error.data);
            return { ...generalError, ...errorData };
        }
        return generalError;
    }
    return 'No data or error in api response.';
};

export default {
    logActions,
    responseActions,
    filterState,
    filterResponse
};
