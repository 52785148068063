import React from 'react';
import classNames from 'classnames';

import NotificationIcon from './NotificationIcon';
import styles from './Notification.module.css';

const Notification = props => {
  const { className: classNameProp, icon, message } = props;
  const className = classNames(classNameProp, styles.container);

  return (
    <div className={className}>
      <NotificationIcon icon={icon} />
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default Notification;
