import { get } from 'lodash';
import inlineFormFields from '../../../components/PatientManagementInlineForm/inlineFormFields';
import { ButtonVariant, AlertVariant, FieldName } from '../../../utils/enums';
import { patientFormTranslatedStrings as messages } from '../../../translations/PatientFormTranslations';
import * as AT from '../../action-types';

const INIT_STATE = {
  initialPatientData: {},
  formFields: inlineFormFields,
  conflictedPatient: null,
  patientCancelButtonVariant: ButtonVariant.PRIMARY,
  errorConflictAlertVariant: AlertVariant.WARNING,
  formHeaderTitleTranslation: messages.headerNew,
  patientCancelButtonTranslation: messages.buttonCancel,
  patientSubmitButtonTranslation: messages.buttonAdd,
  errorGeneralMessageTranslation: messages.errorGeneralMessageNew,
  formLastInvalid: false,
  isEditPatient: false,
  isReadOnlyForm: false,
  isPatientSubmitDisabled: false,
  isErrorConflictAlert: false,
  isErrorConflictPopup: false,
  isErrorGeneralPopup: false,
  isGeneratedChartNumberRequired:false,
  IsClearDataPopup: false,
  isClearPatientFieldsDialogSuppressed: false
};

const CLEAR_FORM = {
  formFields: INIT_STATE.formFields,
  conflictedPatient: INIT_STATE.conflictedPatient,
  formLastInvalid: INIT_STATE.formLastInvalid
}

const getFormField = (state, fieldName) => state.formFields.find(field => field.name === fieldName);

const patientsInlineReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  const feature = get(action, 'meta.feature');

  if (feature !== AT.PATIENT_MANAGEMENT) {
    return state;
  }
  
  switch (type) {
    case AT.SET_CHART_NUMBER_GENERATOR_REQUIRED:
      return {...state, isGeneratedChartNumberRequired: payload};
    case AT.SET_PATIENT_BY_ID:
      return { ...state, initialPatientData: payload };
    case AT.SET_FORM_FIELDS:
      return { ...state, formFields: payload };
    case AT.SET_CONFLICTED_PATIENT:
      return { ...state, conflictedPatient: payload };
    case AT.SET_PATIENT_CANCEL_BUTTON_VARIANT:
      return { ...state, patientCancelButtonVariant: payload };
    case AT.SET_ERROR_CONFLICT_ALERT_VARIANT:
      return { ...state, errorConflictAlertVariant: payload };
    case AT.SET_FORM_HEADER_TITLE_TRANSLATION:
      return { ...state, formHeaderTitleTranslation: payload };
    case AT.SET_PATIENT_CANCEL_BUTTON_TRANSLATION:
      return { ...state, patientCancelButtonTranslation: payload };
    case AT.SET_PATIENT_SUBMIT_BUTTON_TRANSLATION:
      return { ...state, patientSubmitButtonTranslation: payload };
    case AT.SET_ERROR_GENERAL_MESSAGE_TRANSLATION:
      return { ...state, errorGeneralMessageTranslation: payload };
    case AT.SET_FORM_LAST_INVALID:
      return { ...state, formLastInvalid: payload };
    case AT.SET_IS_EDIT_PATIENT:
      return { ...state, isEditPatient: payload };
    case AT.SET_IS_READ_ONLY_FORM:
      return { ...state, isReadOnlyForm: payload };
    case AT.SET_IS_PATIENT_SUBMIT_DISABLED:
      return { ...state, isPatientSubmitDisabled: payload };
    case AT.SET_IS_ERROR_CONFLICT_ALERT:
      return { ...state, isErrorConflictAlert: payload };
    case AT.SET_IS_ERROR_CONFLICT_POPUP:
      return { ...state, isErrorConflictPopup: payload };
    case AT.SET_IS_ERROR_GENERAL_POPUP:
      return { ...state, isErrorGeneralPopup: payload };
    case AT.SET_IS_SHOW_CLEAR_DATA_POPUP:
        return { ...state, IsClearDataPopup: payload };
    case AT.USER_CONFIRMED_CLEARING_FORM:
      const clearedState = { ...state, ...CLEAR_FORM };
      getFormField(clearedState, FieldName.CHART_NUMBER).isDisabled = getFormField(state, FieldName.CHART_NUMBER).isDisabled;
      return clearedState;
    case AT.SET_SUPPRESS_CLEAR_PATIENT_FIELDS_DIALOG:
      return { ...state, isClearPatientFieldsDialogSuppressed: payload };
    default:
      return state;
  }
};

export default patientsInlineReducer;
