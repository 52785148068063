import createMiddleware from '../../utils/middleware-helper';
import * as AT from '../../action-types';
import { sdkSendMessage } from './sdk.actions';
import { changePatientValidationsConfig, createPatient, userConfirmedClearingForm } from '../../feature/patient-management/patient-management.actions';
import { SdkEvents, SdkMessages, SdkResult, rxEventTypes } from './sdk.enums';
import { getMessageObject } from "../../utils";

const { SDK } = AT;

export const middleware = ({ action, dispatch }) => {
  const handleMessageEvent = event => {
    const eventId = +event.data.eventId;
    const data = event.data.data;
    switch (eventId) {
      case rxEventTypes.patientAppSetPatientValidationsConfig:
        dispatch(changePatientValidationsConfig(data));
        break;
      case rxEventTypes.patientAppCreatePatient:
        dispatch(createPatient());
        break;
      case rxEventTypes.patientAppClearPatient:
        dispatch(userConfirmedClearingForm())
        break;
      default:
        break;
    }
  };

  const sdkSendMessageAppReady = {
    sdkRequest: {
      origin: '*',
      message: SdkMessages.SuccessInit,
      result: SdkResult.Success,
      event: SdkEvents.Init
    },
    data: {
      eventId: rxEventTypes.patientAppReady,
      data: 'Hello from Patient App'
    }
  };

  const { payload, type, sdkRequest } = action;

  switch (type) {
    case AT.SDK_INIT: {
      window.addEventListener('message', handleMessageEvent, false);
      dispatch(sdkSendMessage(sdkSendMessageAppReady));
      break;
    }
    case AT.SDK_SEND_MESSAGE: {
      window.parent.postMessage({ ...payload, ...sdkRequest }, sdkRequest.origin);
      break;
    }
    case AT.SDK_CLOSE_ME: {
      const message = getMessageObject();
      message.data = { eventId: payload, data: null};
      dispatch(sdkSendMessage(message));
      break;
    }
    default:
      break;
  }
};

export default createMiddleware({
  feature: SDK
})(middleware);
