export const API_REQUEST = 'API_REQUEST';
const apiAction = (action) => ({
  SOURCE: `${action}`,
  API_REQUEST: `${action}.${API_REQUEST}`,
  SUCCESS: `${action}.SUCCESS`,
  ERROR: `${action}.ERROR`,
});

// shell
export const SHELL = '[SHELL]';
export const APP_READY = 'APP_READY';
export const APP_ERROR = 'APP_ERROR';
export const APP_INIT = 'APP_INIT';
export const GET_APP_CONFIG = apiAction('GET_APP_CONFIG');
export const SET_ZIP_CODE_VISIBILITY = 'SET_ZIP_CODE_VISIBILITY';
export const SET_CHART_NUMBER_AVAILABILITY = 'SET_CHART_NUMBER_AVAILABILITY';
export const VALIDATION_SETTINGS_LOADED = 'REGULATORY_SETTINGS_LOADED';
export const SET_CHART_NUMBER_GENERATOR_REQUIRED = 'SET_CHART_NUMBER_GENERATOR_REQUIRED';
export const SET_APP_CONFIG = 'SET_APP_CONFIG';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_SHOW_LOADER = 'SET_SHOW_LOADER';
export const SAVE_SUPPRESS_CLEAR_PATIENT_FIELDS_DIALOG = 'SAVE_SUPPRESS_CLEAR_PATIENT_FIELDS_DIALOG';

// patient management
export const PATIENT_MANAGEMENT = '[PATIENT_MANAGEMENT]';
export const GET_PATIENT_BY_ID = apiAction('GET_PATIENT_BY_ID');
export const USER_CLICKED_ON_CONFIRM_ERROR_GENERAL_POPUP = 'USER_CLICKED_ON_CONFIRM_ERROR_GENERAL_POPUP';
export const USER_CLICKED_ON_CANCEL_CONFLICT_POPUP = 'USER_CLICKED_ON_CANCEL_CONFLICT_POPUP';
export const USER_CLICKED_ON_CONFIRM_CONFLICT_POPUP = 'USER_CLICKED_ON_CONFIRM_CONFLICT_POPUP';
export const USER_CLICKED_ON_CANCEL_PATIENT_FORM = 'USER_CLICKED_ON_CANCEL_PATIENT_FORM';
export const USER_CLICKED_ON_SUBMIT_PATIENT_FORM = 'USER_CLICKED_ON_SUBMIT_PATIENT_FORM';
export const USER_CHANGED_INPUT_PATIENT = 'USER_CHANGED_INPUT_PATIENT';
export const SET_FORM_LAST_INVALID = 'SET_FORM_LAST_INVALID';
export const SET_IS_ERROR_CONFLICT_ALERT = 'SET_IS_ERROR_CONFLICT_ALERT';
export const SET_IS_ERROR_CONFLICT_POPUP = 'SET_IS_ERROR_CONFLICT_POPUP';
export const SET_IS_ERROR_GENERAL_POPUP = 'SET_IS_ERROR_GENERAL_POPUP';
export const SET_FORM_HEADER_TITLE_TRANSLATION = 'SET_FORM_HEADER_TITLE_TRANSLATION';
export const SET_PATIENT_CANCEL_BUTTON_TRANSLATION = 'SET_PATIENT_CANCEL_BUTTON_TRANSLATION';
export const SET_PATIENT_SUBMIT_BUTTON_TRANSLATION = 'SET_PATIENT_SUBMIT_BUTTON_TRANSLATION';
export const SET_ERROR_GENERAL_MESSAGE_TRANSLATION = 'SET_ERROR_GENERAL_MESSAGE_TRANSLATION';
export const SET_IS_SHOW_CLEAR_DATA_POPUP = 'SET_IS_SHOW_CLEAR_DATA_POPUP';
export const USER_CALL_CLEAR_PATIENT_ACTION = 'USER_CALL_CLEAR_PATIENT_ACTION';
export const USER_CONFIRMED_CLEARING_FORM = 'USER_CONFIRMED_CLEARING_FORM';
export const SET_SUPPRESS_CLEAR_PATIENT_FIELDS_DIALOG = 'SET_SUPPRESS_CLEAR_PATIENT_FIELDS_DIALOG';

export const CHECK_IF_PATIENT_HAS_CONFLICTS_NEW = apiAction('CHECK_IF_PATIENT_HAS_CONFLICTS_NEW');
export const CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT = apiAction('CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT');

export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_PATIENT_CANCEL_BUTTON_VARIANT = 'SET_PATIENT_CANCEL_BUTTON_VARIANT';
export const SET_ERROR_CONFLICT_ALERT_VARIANT = 'SET_ERROR_CONFLICT_ALERT_VARIANT';
export const SET_PATIENT_BY_ID = 'SET_PATIENT_BY_ID';
export const UPDATE_PATIENT = apiAction('UPDATE_PATIENT');
export const CHANGE_PATIENT_VALIDATIONS_CONFIG = 'CHANGE_PATIENT_VALIDATIONS_CONFIG';
export const SET_FORM_FIELDS = 'SET_FORM_FIELDS';
export const SET_IS_EDIT_PATIENT = 'SET_IS_EDIT_PATIENT';
export const SET_IS_READ_ONLY_FORM = 'SET_IS_READ_ONLY_FORM';
export const SET_IS_PATIENT_SUBMIT_DISABLED = 'SET_IS_PATIENT_SUBMIT_DISABLED';
export const USER_CLICKED_ON_AN_ALREADY_PAIRED_PATIENT = 'USER_CLICKED_ON_AN_ALREADY_PAIRED_PATIENT';
export const PARENT_ASK_CREATE_PATIENT = 'PARENT_ASK_CREATE_PATIENT';
export const CALL_SEARCH_PATIENT_TO_PARENT = 'CALL_SEARCH_PATIENT_TO_PARENT';
export const USER_LOADED_EXISTING_PATIENT = 'USER_LOADED_EXISTING_PATIENT';

// patient-search
export const PATIENT_SEARCH = '[PATIENT_SEARCH]';
export const GET_PATIENTS = apiAction('GET_PATIENTS');
export const USER_CLICKED_ON_SELECT_PATIENT = 'USER_CLICKED_ON_SELECT_PATIENT';
export const USER_DECIDE_TO_PAIR_PATIENT = 'USER_DECIDE_TO_PAIR_PATIENT';

// common patientSearch + createPation
export const UPDATE_HOST_PATIENT = 'UPDATE_HOST_PATIENT';
export const SET_CONFLICTED_PATIENT = 'SET_CONFLICTED_PATIENT';
export const SAVE_PATIENT = apiAction('SAVE_PATIENT');

// sdk
export const SDK = '[SDK]';
export const SDK_INIT = 'SDK_INIT';
export const SDK_SEND_MESSAGE = 'SDK_SEND_MESSAGE';
export const SDK_CLOSE_ME = 'SDK_CLOSE_ME';
