import { required, length, format, combine } from 'redux-form-validators';
import { errorTranslatedStrings } from '../translations/ErrorsTranslations';
import { daysMask, monthsMask } from '../consts';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat)

// details of how to create/use redux-form-validators is at https://github.com/gtournie/redux-form-validators

// exported
const lengthError = (translatedField, maxLength) =>
  length({
    max: maxLength,
    msg: lengthErrorMessage(translatedField, maxLength),
    memoize: false
  });
const oneCharacterLengthError = (translatedField, maxLength) =>
  length({
    max: maxLength,
    msg: oneCharacterErrorMessage(translatedField),
    memoize: false
  });
const requiredError = () => required({ msg: requiredErrorMessage, memoize: false });

const dateOfBirthError = (translatedField, format) => value => {
  if (!value) {
    return undefined;
  }

  const date = dayjs(value, format, true);
  
  if (!date.isValid()) {
      
      const containMonth = format.toUpperCase().indexOf(monthsMask) >= 0;
      const containDays = format.toUpperCase().indexOf(daysMask) >= 0;
      let message = errorTranslatedStrings.formatDateErrorMessage;
      
      if(!containDays){
          if(!containMonth){
              message = errorTranslatedStrings.yearFormatDateErrorMessage;
          } else {
              message = errorTranslatedStrings.yearMonthFormatDateErrorMessage;
          }
      }
      
      return formatDateErrorMessage(translatedField, message);
  }

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (dayjs(yesterday).diff(date) <= 0 || dayjs('1900/01/01').diff(date) > 0) {
    return DateBoundsErrorMessage;
  }
};

const requiredDateOfBirthError = (translatedField, format) => combine(requiredError(), dateOfBirthError(translatedField, format));
const middleInitialLengthError = () => length({ max: 1, msg: middleInitialLengthErrorMessage, memoize: false });
const middleInitialCharactersOnlyError = () =>
  format({
    with: NO_SPECIAL_CHARS_REGEX,
    msg: middleInitialCharactersOnlyErrorMessage,
    memoize: false
  });

const emojiPatternError = translatedField =>
  format({
    with: NO_EMOJI_REGEX,
    msg: invalidCharactersErrorMessage(translatedField),
    memoize: false
  });

const patientZipCodePatternError = translatedField =>
  format({
    with: ZIP_CODE_CHARS_REGEX,
    msg: patientZipCodePatternErrorMessage(translatedField),
    memoize: false
  });

// internal
const lengthErrorMessage = (translatedField, maxLength) => [
  translatedField,
  errorTranslatedStrings.lengthErrorMessage(maxLength)
];
const oneCharacterErrorMessage = translatedField => [translatedField, errorTranslatedStrings.oneCharacterLengthErrorMessage];

const invalidCharactersErrorMessage = translatedField => [
  translatedField,
  errorTranslatedStrings.invalidCharactersErrorMessage
];
const patientZipCodePatternErrorMessage = translatedField => [
  translatedField,
  errorTranslatedStrings.zipCodeInvalidCharactersErrorMessage
];
const requiredErrorMessage = [errorTranslatedStrings.requiredErrorMessage];
const DateBoundsErrorMessage = [errorTranslatedStrings.DateBoundsErrorMessage];

const formatDateErrorMessage = (translatedField, message) => [translatedField, message];

const middleInitialLengthErrorMessage = [errorTranslatedStrings.middleInitialLengthErrorMessage];
const middleInitialCharactersOnlyErrorMessage = [errorTranslatedStrings.middleInitialCharactersOnlyErrorMessage];

const NO_EMOJI_REGEX = /(^$)|(^[^\uD800-\uf8ff]+$)/;
const NO_SPECIAL_CHARS_REGEX = /(^$)|(^[a-zA-Z0-9 ]+$)/;
const ZIP_CODE_CHARS_REGEX = /(^$)|(^[a-zA-Z0-9 ]+$)/;

export default {
  lengthError,
  oneCharacterLengthError,
  requiredError,
  dateOfBirthError,
  middleInitialLengthError,
  middleInitialCharactersOnlyError,
  emojiPatternError,
  patientZipCodePatternError,
  requiredDateOfBirthError
};
