export const GenderType = {
  Male: { text: 'M', value: 0 },
  Female: { text: 'F', value: 1 }
};

export const FieldName = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  MI: 'mi',
  GENDER: 'gender',
  GENDER_REQUIRED: 'genderRequired',
  DATE_OF_BIRTH: 'dateOfBirth',
  DATE_OF_BIRTH_REQUIRED: 'dateOfBirthRequired',
  CHART_NUMBER: 'chartNumber',
  ZIP_CODE: 'zipCode'
};

export const ButtonVariant = {
  SECONDARY: 'secondary',
  PRIMARY: 'primary'
};

export const AlertVariant = {
  DANGER: 'danger',
  WARNING: 'warning'
};

export const PatientType = {
  ITERO: 1,
  IDS: 2,
  ITERO_IDS_PAIR: 3
};

export const ApiStatusCodes = {
  SUCCESS: 200,
  CREATED: 201,
  REDIRECT: 302,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  PAGE_NOT_FOUND: 404,
  CONFLICT: 409
};

export const SupportedPatientTypes = [
  PatientType.ITERO
  // PatientType.IDS,
  // PatientType.ITERO_IDS_PAIR
];
