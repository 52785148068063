import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import utils from '../../utils/utils';

const commonTranslationValues = {
  breakingLine: <br />
};

function TranslatedContent(props) {
  const { contentArray } = props;

  const formatInput = content => {
    if (utils.isString(content)) {
      return <span key={content}>{content}</span>;
    }
    if (utils.isTranslationObject(content)) {
      content.values = { ...commonTranslationValues, ...content.values };
      return <FormattedMessage key={content.id} {...content} />;
    }
    return '';
  };

  return <>{contentArray.map(content => formatInput(content))}</>;
}

TranslatedContent.propTypes = {
  /**
   * Content to be translated.
   */
  contentArray: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        /**
         * Default translation message
         */
        defaultMessage: PropTypes.string,
        /**
         * Translation ID
         */
        id: PropTypes.string,
        /**
         * Values to render inside translation templates
         */
        values: PropTypes.shape({ breakingLine: PropTypes.node })
      })
    ])
  ),
  intl: intlShape.isRequired
};

export default injectIntl(TranslatedContent);
