import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import TranslatedContent from '../../Translations/TranslatedContent';
import './TextInput.scss';

function TextInput(props) {
  const {
    name,
    value,
    readOnly,
    onChange,
    touched,
    error,
    maxLength,
    inputLabel,
    required,
    type,
    placeholder,
    forceShowError,
    id,
    onBlur
  } = props;

  const isError = !!(touched && error) || forceShowError;

  return (
    <TextField
      autoComplete={'off'}
      error={isError}
      id={id}
      name={name}
      value={value}
      disabled={readOnly}
      onChange={onChange}
      inputProps={{
        maxLength: maxLength
      }}
      label={inputLabel}
      helperText={isError && error && <TranslatedContent contentArray={error} />}
      required={required}
      fullWidth
      type={type}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  );
}

TextInput.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  forceShowError: PropTypes.bool
};

export default TextInput;
