import { get } from 'lodash';
import utils from '../../../utils/utils';
import { getFieldValidations } from '../../../components/PatientManagementForm/formFields';
import { updateLastNameMaxLength, fieldValidations } from '../../../components/PatientManagementForm/formFieldsValidations';
import { PatientViewModel } from '../../../models/index';
import { ApiStatusCodes, PatientType, FieldName } from '../../../utils/enums';
import { patientFormTranslatedStrings } from '../../../translations/PatientFormTranslations';
import validators from '../../../validation/validators';

const { dateOfBirth } = patientFormTranslatedStrings;

const parseToDto = patientObj => {
  return new PatientViewModel(patientObj);
};

const getPatientDto = (patient, dateOfBirthFormat, regulatoryDateOfBirthMask, formatDateOfBirthToFullDate = true) => {
  const patientJsonStr = JSON.stringify(patient, null, 2);
  const patientObj = JSON.parse(patientJsonStr);
  if(formatDateOfBirthToFullDate) {
    patientObj.dateOfBirth = utils.getFullDateOfBirth(patientObj.dateOfBirth,
        dateOfBirthFormat, regulatoryDateOfBirthMask);
  }
  else if(dateOfBirthFormat) {
    patientObj.dateOfBirth = utils.getShortDateOfBirth(patientObj.dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask);
  }
  
  return parseToDto(patientObj);
};

const isIDSPatient = type => {
  return type === PatientType.IDS || type === PatientType.ITERO_IDS_PAIR;
};

const isIteroPatient = type => {
  return type === PatientType.ITERO || type === PatientType.ITERO_IDS_PAIR;
};

const isValidPatientId = patientId => {
  return patientId && utils.isGuid(patientId);
};

const isValidPatientType = patientType => {
  return Object.values(PatientType).includes(+patientType);
};

const isValidEndpoint = endpoint => {
  return utils.isValidUrl(endpoint);
};

const isValidPatientAndTypeUrl = (patientId, patientType, endpoint) => {
  return utils.isGuid(patientId) && isValidPatientType(patientType) && isValidEndpoint(endpoint);
};

const isValidPatientUrl = (patientId, companyId, endpoint) => {
  return utils.isGuid(patientId) && !isNaN(companyId) && isValidEndpoint(endpoint);
};

const isLogPatientId = patientId => {
  return patientId && !isValidPatientId(patientId);
};

const isLogPatientType = patientType => {
  return patientType && !isValidPatientType(patientType);
};

const isLogEndpoint = endpoint => {
  return !isValidEndpoint(endpoint);
};

const isLogUrlParams = (patientId, patientType, endpoint) => {
  return isLogPatientId(patientId) || isLogPatientType(patientType) || isLogEndpoint(endpoint);
};

const isErrorStatus = (payload, apiStatusCode) => {
  return payload && get(payload, 'error.status') === apiStatusCode;
};

const getPatientConflict = payload => {
  if (isErrorStatus(payload,  ApiStatusCodes.CONFLICT)) {
    const conflictedPatients = get(payload, 'error.data.conflictedPatients', []);
    return conflictedPatients[0] || null;
  }
  return null;
};

const getFieldValidationsConfig = (validationsConfig, formField) => {
  return validationsConfig.find(validation => {
    return formField.name === validation.name;
  });
};

const getNewFieldRequirements = (validationConfig, formField) => {
  formField.isRequired = validationConfig.isRequired;
  formField.validations = getFieldValidations(validationConfig);
  return { ...formField };
};

const getNewFormFieldsRequirements = (formFields, validationsConfig) => {
  return formFields.map(formField => {
    const validationConfig = getFieldValidationsConfig(validationsConfig, formField);
    if (!validationConfig) {
      return { ...formField };
    }
    return getNewFieldRequirements(validationConfig, formField);
  });
};

const getNewFormFieldsValidations = (formFields, maxPatientLastNameLength, dobFormat) => {
  return formFields.map(formField => {
    switch (true) {
      case formField.name === FieldName.LAST_NAME && !!maxPatientLastNameLength:
          updateLastNameMaxLength(maxPatientLastNameLength);
          formField.validations = fieldValidations(formField.name);
        break;
      case formField.name === FieldName.DATE_OF_BIRTH && !!dobFormat:
        formField.validations = validators.dateOfBirthError(dateOfBirth, dobFormat);
        break;
      case formField.name === FieldName.DATE_OF_BIRTH_REQUIRED && !!dobFormat:
        formField.validations = validators.requiredDateOfBirthError(dateOfBirth, dobFormat);
        break;
      default:
        break;
    }
    return { ...formField };
  });
}

const getNewFormFieldsIsShowErrorBorder = (formFields, isReadOnlyForm, conflictedPatient) => {
  const isShowErrorBorderAllowed = !isReadOnlyForm && conflictedPatient;
  return formFields.map(field => {
    return { ...field, isShowErrorBorder: isShowErrorBorderAllowed ? field.isKeyField : false };
  });
};

const getNewFormFieldsVisibility = (formFields, isZipCodeEnabled) => {
  return formFields.map(field => {
    return { ...field, isVisible: field.name === FieldName.ZIP_CODE ? isZipCodeEnabled : field.isVisible };
  });
};

const getNewFormFieldsAvailability = (formFields, fieldName, isDisabled) => {
  return formFields.map(field => {
    return { ...field, isDisabled: field.name === fieldName ? isDisabled : field.isDisabled };
  });
};

export default {
  getPatientDto,
  isIDSPatient,
  isIteroPatient,
  isValidPatientAndTypeUrl,
  isValidPatientUrl,
  isLogUrlParams,
  getPatientConflict,
  getNewFormFieldsRequirements,
  getNewFormFieldsIsShowErrorBorder,
  getNewFormFieldsVisibility,
  getNewFormFieldsAvailability,
  getNewFormFieldsValidations
};
