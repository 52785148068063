import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import { isValidDateFormat } from '../../../redux/utils/index';

import genericStyles from '../../UI/GenericUiStyles.module.scss';
import TranslatedContent from '../../Translations/TranslatedContent';

export const handleChange = (e,  onChange) => {
  onChange(e);
};

function DateInput(props) {
  const { id, name, value, readOnly, onChange, touched, onFocus, error, format, forceShowError, inputLabel } = props;

  const isError = (!!touched && !!error) || forceShowError;
  const label = inputLabel ? <TranslatedContent contentArray={inputLabel} /> : '';
  const errorMessage = isError ? <TranslatedContent contentArray={error} /> : '';

  const parsedFormat = isValidDateFormat(format) ? format : 'YYYY/MM/DD';

  const mapDateOfBirthToDateInputMask = format => {
    return isValidDateFormat(format) ? format.replace(/\w/g, '9') : '9999/99/99';
  };

  const inputMaskClassName = classNames(
    { [`${genericStyles['input-field-error']}`]: isError },
    { [`${genericStyles['no-border']}`]: !isError },
    'form-control'
  );

  return (
    <InputMask
      value={value}
      disabled={readOnly}
      onChange={e => handleChange(e, onChange)}
      onFocus={onFocus}
      type="tel"
      placeholder={parsedFormat}
      className={inputMaskClassName}
      mask={mapDateOfBirthToDateInputMask(format)}
      maskChar={null}
      permanents="[5,8]"
    >
      {() => (
        <TextField
          autoComplete={'off'}
          disabled={readOnly}
          id={id}
          name={name}
          error={error}
          label={label}
          helperText={errorMessage}
          fullWidth
          placeholder={parsedFormat}
        />
      )}
    </InputMask>
  );
}

DateInput.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  forceShowError: PropTypes.bool
};

export default DateInput;
