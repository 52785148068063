import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './Spinner.module.scss';

const PatientAppSpinner = props => {
  const { showSpinner } = props;

  return (
    showSpinner && (
      <div className={styles['spinner-container']}>
        <Spinner animation="border" role="status" className={styles.spinner} />
      </div>
    )
  );
};

PatientAppSpinner.propTypes = {
  /**
   * If true, spinner will be visible
   */
  showSpinner: PropTypes.bool
};

PatientAppSpinner.defaultProps = {
  showSpinner: true
};

export default PatientAppSpinner;
