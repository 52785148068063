import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { sdkInit, sdkCloseMe } from '../../redux/core/sdk/sdk.actions';
import { rxEventTypes } from '../../redux/core/sdk/sdk.enums';
import { SupportedPatientTypes } from '../../utils/enums';
import {
  getPatients,
  userClickedOnSelectPatient,
  userClickedOnCancelConflictPopup,
  userDecideToPairPatient
} from '../../redux/feature/patient-search/patient-search.actions';

import TranslatedContent from '../Translations/TranslatedContent';
import QuestionPopup from '../UI/QuestionPopup';
import { patientSearchTranslatedStrings as messages } from '../../translations/PatientSearchTranslations';

import { doctorTableProps, getConflictMessage, mapPatientsToTable, pairedDoctorTableProps } from './PatientSearch.utils';
import PatientSearch from './PatientSearch';

class PatientSearchContainer extends Component {
  static propTypes = {
    /**
     * Conflicted patient object if there is one
     */
    conflictedPatient: PropTypes.object,
    /**
     * Get patient list
     */
    getPatients: PropTypes.func.isRequired,

    /**
     * Patients to display
     */
    patients: PropTypes.arrayOf(PropTypes.object),
    /**
     * function to tell the host to close the patient app window
     */
    sdkCloseMe: PropTypes.func.isRequired,
    /**
     *
     */
    sdkInit: PropTypes.func.isRequired,
    /**
     * Handle case of user clicks on select button.
     */
    userClickedOnSelectPatient: PropTypes.func.isRequired,
    /**
     * current company of the doctor.
     */
    companyId: PropTypes.string,
    /**
     * Determines if Gender and Date of Birth fields should be hidden.
     */
    shouldHideGenderAndDob: PropTypes.bool
  };

  

  selectedPatient = null;


  getTableColumns() {
    const { isDoctorIdsPaired, isZipCodeEnabled, shouldHideGenderAndDob } = this.props;

    const { tableColumns } = isDoctorIdsPaired ? pairedDoctorTableProps : doctorTableProps;
    const shouldRemoveZipCodeColumn = isDoctorIdsPaired && !isZipCodeEnabled;
    let filteredTableColumns = [...tableColumns];
    if (shouldRemoveZipCodeColumn) {
      filteredTableColumns = filteredTableColumns.filter(col => col.dataKey !== 'zipCode');
    }
    if (shouldHideGenderAndDob) {
      filteredTableColumns = filteredTableColumns.filter(col => (col.dataKey !== 'genderText' && col.dataKey !== 'dateOfBirth'))
    }
    return filteredTableColumns;
  }

  getRawPatient(patientID) {
    const { patients } = this.props;
    const selectedPatient = patients.find(patient => patient.id === patientID);
    const { icon, id, genderText, ...rawPatientData } = selectedPatient;

    return rawPatientData;
  }

  handleCancel = () => {
    const { sdkCloseMe } = this.props;
    sdkCloseMe(rxEventTypes.patientAppSelectedPatient);
  };

  handleRefresh = () => {
    const { getPatients, companyId } = this.props;
    const forceCacheUpdate = true;
    getPatients({patientTypes: SupportedPatientTypes, forceCacheUpdate, companyId});
  };

  handlePatientSelect = patientID => {
    const { userClickedOnSelectPatient, companyId } = this.props;
    const rawPatientData = this.getRawPatient(patientID);
    this.selectedPatient = rawPatientData;
    userClickedOnSelectPatient(rawPatientData, companyId);
  };


  render() {
    const {
      isDoctorIdsPaired,
      patients,
      conflictedPatient,
      companyId,
      userClickedOnCancelConflictPopup,
      userDecideToPairPatient
    } = this.props;
    const tableProps = isDoctorIdsPaired ? pairedDoctorTableProps : doctorTableProps;
    const tableColumns = this.getTableColumns();
    const conflictedMessage = conflictedPatient ? getConflictMessage(conflictedPatient) : null;

    return (
      <>
        <PatientSearch
          {...tableProps}
          patients={patients}
          tableColumns={tableColumns}
          onCancel={this.handleCancel}
          onPatientSelect={this.handlePatientSelect}
          onRefresh={this.handleRefresh}
        />
        <QuestionPopup
          open={conflictedPatient}
          title={<TranslatedContent contentArray={[messages.question]} />}
          message={conflictedMessage}
          cancelText={<TranslatedContent contentArray={[messages.no]} />}
          confirmText={<TranslatedContent contentArray={[messages.yes]} />}
          handleCancelClick={userClickedOnCancelConflictPopup}
          handleConfirmClick={() => userDecideToPairPatient(this.selectedPatient, companyId)}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    patientSearch: { conflictedPatient, patientsList },
    shell: {
      config: { isDoctorIdsPaired, isZipCodeEnabled },
      fromUrl: { companyId, shouldHideGenderAndDob }
    }
  } = state;
  return {
    conflictedPatient,
    isDoctorIdsPaired,
    isZipCodeEnabled,
    companyId,
    shouldHideGenderAndDob,
    patients: mapPatientsToTable(patientsList,state)
  };
};

const mapDispatchToProps = {
  getPatients,
  sdkCloseMe,
  sdkInit,
  userDecideToPairPatient,
  userClickedOnSelectPatient,
  userClickedOnCancelConflictPopup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientSearchContainer);
