import React from 'react';
import styles from './InlineHeader.module.scss';
import BaseButton from '../BaseButton';
import TranslatedContent from '../../Translations/TranslatedContent';
import { patientFormTranslatedStrings as messages } from '../../../translations/PatientFormTranslations';
import { Search } from '@material-ui/icons';
import { IconButton } from '../IconButton/IconButton';

export default class InlineHeader extends React.Component {
  getHeader() {
    return (
      <header className={styles.root}>
        <div className={styles.leftActions}>
          <h1 className={styles.title}>
            <TranslatedContent contentArray={[messages.patient]} />:
          </h1>
          <div className={styles.searchIcon} onClick={() => this.searchButtonClick(this.props.searchHandler)}></div>
        </div>
        <div>
          <IconButton
            id="searchPatientButton"
            className={styles.searchButton}
            onClick={() => this.searchButtonClick(this.props.searchHandler)}
          >
            <Search />
          </IconButton>
          <BaseButton
            color="secondary"
            onClick={() => this.clearButtonClick(this.props.clearHandler)}
            className={styles.clearButton}
            disabled={!this.props.isDirty}
          >
            <TranslatedContent contentArray={[messages.buttonClear]} />
          </BaseButton>
        </div>
      </header>
    );
  }

  clearButtonClick = clearHandler => clearHandler();
  searchButtonClick = searchHandler => searchHandler();

  render() {
    return this.getHeader();
  }
}
