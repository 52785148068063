import { isEmpty, get, includes } from 'lodash';
import qs from 'qs';

import * as AT from '../../action-types';
import createMiddleware from '../../utils/middleware-helper';
import { sdkSendMessage } from '../sdk/sdk.actions';
import { setShowLoader } from '../shell/shell.actions';
import { ApiStatusCodes } from '../../../utils/enums';
import { rxEventTypes, SdkMessages, SdkResult, SdkEvents } from '../sdk/sdk.enums';

import { send, source } from './axios-wrapper';
import { apiError, apiSuccess } from './api.actions';

let prevRequestUrl = null;

const middleware = () => {};

const getResponseResults = ({ res }) => {
  switch (res.data.HttpStatusCode) {
    case 400: {
      return {
        message: SdkMessages.FailureSave,
        result: SdkResult.Failed
      };
    }
    case 409: {
      return {
        message: SdkMessages.PatientAlreadyExists,
        result: SdkResult.Failed
      };
    }
    default: {
      return {
        message: SdkMessages.SuccessSave,
        result: SdkResult.Success
      };
    }
  }
};

const handleSuccess = ({ dispatch, response }) => {
  const { res, action } = response;
  const { shouldSendToParent, internalEventId } = action;
  const { sourceAction } = action.meta;

  let sdkSendMsg = {
    data: {
      data: res.data,
      eventId: internalEventId
    },
    sdkRequest: {
      origin: '*',
      event: SdkEvents.SendMessage,
      internalEventId: internalEventId
    }
  };

  sdkSendMsg.sdkRequest = {
    ...sdkSendMsg.sdkRequest,
    ...getResponseResults({ res })
  };

  if (shouldSendToParent) {
    dispatch([apiSuccess({ res, sourceAction, meta: action.meta }), sdkSendMessage(sdkSendMsg)]);
  } else {
    dispatch(apiSuccess({ res, sourceAction, meta: action.meta }));
  }
};

const handleError = ({ dispatch, response }) => {
  const { err, action } = response;
  const { internalEventId } = action;
  const { sourceAction } = action.meta;
  const error = {
    ...err.response,
    message: get(err, 'response.data.message') || 'Error'
  };

  const sdkSendMsg = {
    data: {
      data: error,
      eventId: rxEventTypes.patientAppRedirect
    },
    sdkRequest: {
      origin: '*',
      message: SdkMessages.FailureSave,
      result: SdkResult.Failed,
      event: SdkEvents.SendMessage,
      internalEventId: internalEventId
    }
  };

  if (error.status === ApiStatusCodes.UNAUTHORIZED) {
    sdkSendMsg.data.data = { status: error.status, message: error.message };
    dispatch([sdkSendMessage(sdkSendMsg)]);
  } else {
    dispatch([apiError({ error, sourceAction, meta: action.meta })]);
  }
};

const handleResponse = ({ dispatch, response }) => {
  // Cancel loader only if this api call started the loader
  const shouldCancelLoader = response.action.meta.setLoader;
  if (shouldCancelLoader) {
    dispatch(setShowLoader(false));
  }
  if (response.err) {
    handleError({ dispatch, response });
  } else if (response.res) {
    handleSuccess({ dispatch, response });
  }
};

const goThroughOverride = async ({ dispatch, action, getState }) => {
  if (includes(action.type, AT.API_REQUEST)) {
    const { url, withCredentials, setLoader, cancelPrevRequest } = action.meta;
    const endpoint = getState().shell.fromUrl.endpoint || action.meta.endpoint;
    const method = action.meta.method.toLowerCase();
    const shouldShowLoader = setLoader;
    const shouldCancelPrevRequest = cancelPrevRequest;
    const data = action.payload;

    const options = {
      method: method,
      url: endpoint + url,
      withCredentials
    };

    if (method !== 'get' && data) {
      options.data = data;
    }

    if (method === 'get' && data && !isEmpty(data)) {
      options.url += `?${qs.stringify(data)}`;
    }

    try {
      if (shouldCancelPrevRequest && url === prevRequestUrl) {
        source.cancel('canceled');
      }
      prevRequestUrl = url;
      if (shouldShowLoader) {
        dispatch(setShowLoader(true));
      }
      const res = await send(options);
      handleResponse({ dispatch, response: { res, action } });
    } catch (err) {
      handleResponse({ dispatch, response: { err, action } });
    }
  }
};

export default createMiddleware({
  feature: '',
  goThroughOverride
})(middleware);
