import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import pt from 'react-intl/locale-data/pt';
import ru from 'react-intl/locale-data/ru';
import th from 'react-intl/locale-data/th';
import tr from 'react-intl/locale-data/tr';
import zh from 'react-intl/locale-data/zh';

import de_DE from './json/de-DE.json';
import en_US from './json/en-US.json';
import es_ES from './json/es-ES.json';
import fr_FR from './json/fr-FR.json';
import it_IT from './json/it-IT.json';
import ja_JP from './json/ja-JP.json';
import ko_KR from './json/ko-KR.json';
import pt_BR from './json/pt-BR.json';
import ru_RU from './json/ru-RU.json';
import th_TH from './json/th-TH.json';
import tr_TR from './json/tr-TR.json';
import zh_CN from './json/zh-CN.json';
import zh_TW from './json/zh-TW.json';

export const translations = {
  'de-de': de_DE,
  'en-us': en_US,
  'es-es': es_ES,
  'fr-fr': fr_FR,
  'it-it': it_IT,
  'ja-jp': ja_JP,
  'ko-kr': ko_KR,
  'pt-br': pt_BR,
  'ru-ru': ru_RU,
  'th-th': th_TH,
  'tr-tr': tr_TR,
  'zh-cn': zh_CN,
  'zh-tw': zh_TW
};

export const localeData = [
  ...de,
  ...en,
  ...es,
  ...fr,
  ...it,
  ...ja,
  ...ko,
  ...pt,
  ...ru,
  ...th,
  ...tr,
  ...zh
];
