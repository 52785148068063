import { createStore, compose, applyMiddleware } from 'redux';
import reducer from './reducers';
import actionSplitterMiddleware from './core/action-splitter.middleware';
import apiMiddleware from './core/api/api.middleware';
import shellMiddleware from './core/shell/shell.middleware';
import loggerMiddleware from './core/logger/logger.middleware';
import patientSearchMiddleware from './feature/patient-search/patient-search.middleware';
import patientManagementMiddleware from './feature/patient-management/patient-management.middleware';
import sdkMiddleware from './core/sdk/sdk.middleware';

const coreMiddlewares = [actionSplitterMiddleware, apiMiddleware, shellMiddleware, sdkMiddleware, loggerMiddleware];

const featureMiddlewares = [patientManagementMiddleware, patientSearchMiddleware];

const middlewares = [...coreMiddlewares, ...featureMiddlewares];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const configureStore = () => createStore(reducer, enhancer);

export default configureStore;
