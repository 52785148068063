import { reset } from 'redux-form';
import { formName } from '../../../components/PatientManagementInlineForm/PatientManagementInlineForm';
import * as AT from '../../action-types';
import patientUtils from './patient-management.utils';
import { patientFormTranslatedStrings as messages } from '../../../translations/PatientFormTranslations';
import createMiddleware from '../../utils/middleware-helper';
import { ApiStatusCodes, AlertVariant, ButtonVariant, FieldName } from '../../../utils/enums';
import { sdkCloseMe, sdkSendMessage } from '../../core/sdk/sdk.actions';
import { rxEventTypes } from '../../core/sdk/sdk.enums';
import { getMessageObject, isValidDateFormat } from '../../utils';
import {
  getPatientByIdAndType,
  getPatientById,
  setPatientById,
  setIsEditPatient,
  setIsReadOnlyForm,
  setIsPatientSubmitDisabled,
  setFormFields,
  setConflictedPatient,
  setPatientCancelButtonVariant,
  setErrorConflictAlertVariant,
  setFormHeaderTitleTranslation,
  setPatientCancelButtonTranslation,
  setPatientSubmitButtonTranslation,
  setErrorGeneralMessageTranslation,
  setFormLastInvalid,
  setIsErrorConflictAlert,
  setIsErrorConflictPopup,
  setIsErrorGeneralPopup,
  checkIfPatientHasConflictsNew,
  checkIfPatientHasConflictsEdit,
  updatePatient,
  savePatient,
  updateHostPatient,
  setIsClearDataPopup,
  userClickedOnSubmitPatientForm
} from './patient-management.actions';
import utils from '../../../utils/utils';
import {fullDateOfBirthMask} from "../../../consts";

const featureName = AT.PATIENT_MANAGEMENT;

const submitPatientForm = (form, patientId, companyId, isUpdate, dateOfBirthFormat) => {
  const patient = patientUtils.getPatientDto(form, dateOfBirthFormat, null, false);
  if (patientId || isUpdate) {
    return updatePatient({ patient, companyId });
  }
  return savePatient({ patient, companyId });
};

const checkIfPatientHasConflicts = (
  dispatch,
  form,
  isEditPatient,
  companyId,
  dateOfBirthFormat,
  setIsLastInvalid = false,
  invalid = false
) => {
  const patientObj = patientUtils.getPatientDto(form, dateOfBirthFormat);
  const cancelPrevRequest = true;
  let actions = setIsLastInvalid ? [setFormLastInvalid(invalid)] : [];
  if (isEditPatient) {
    actions.push(checkIfPatientHasConflictsEdit({ patient: patientObj, companyId, cancelPrevRequest }));
  } else {
    actions.push(checkIfPatientHasConflictsNew({ patient: patientObj, companyId, cancelPrevRequest }));
  }
  dispatch(actions);
};

const getActionsWhenPatientHasNoConflicts = (formState) => {
  const newFormFields = patientUtils.getNewFormFieldsIsShowErrorBorder(
    formState.formFields,
    formState.isReadOnlyForm,
    null
  );
  let actions = [setIsErrorConflictAlert(false), setConflictedPatient(null), setFormFields(newFormFields)];
  if (formState.isEditPatient) {
    actions.push(setIsPatientSubmitDisabled(false));
  } else {
    actions.push(setPatientInConflict(false));
  }
  return actions;
};

const setPatientInConflict = (inConflict) => {
  const message = getMessageObject();
  message.data = {
    eventId: rxEventTypes.patientAppPatientInConflict,
    data: inConflict
  };
  return sdkSendMessage(message);
};

const loadPatient = (fromUrlState, dispatch) => {
  const patientId = fromUrlState.patientId;
  const patientType = fromUrlState.patientType;
  const companyId = fromUrlState.companyId;
  const endpoint = fromUrlState.endpoint;
  const isMidcSource = fromUrlState.isMidcSource;
  let actions = [];
  if (isMidcSource && patientUtils.isValidPatientUrl(patientId, patientType, endpoint)) {
    actions.push(getPatientById({ id: patientId, companyId: companyId, endpoint }));
  } else if (!isMidcSource && patientUtils.isValidPatientAndTypeUrl(patientId, patientType, endpoint)) {
    actions.push(getPatientByIdAndType({ id: patientId, type: patientType, endpoint }));
  } else if (patientUtils.isLogUrlParams(patientId, patientType, endpoint)) {
    console.error('Wrong patient details in edit patient url');
  }
  if (patientId) {
    actions.push(setFormHeaderTitleTranslation(messages.headerEdit));
  }
  dispatch(actions);
}

function getDateOfBirthFormat(state) {
  return state.shell.fromUrl.dateOfBirthFormat ? state.shell.fromUrl.dateOfBirthFormat
      : state.shell.config.regulatorySettings ? state.shell.config.regulatorySettings.dobMask : fullDateOfBirthMask;
}

export const middleware = ({ dispatch, getState, action }) => {
  const { type, payload } = action;
  const state = getState();

  const formState = state.shell.fromUrl.isInlineMode ? state.patientManagementInline : state.patientManagement;

  switch (type) {
    case AT.GET_PATIENT_BY_ID.SUCCESS: {
      const { patients } = payload.data;
      const companyId = state.shell.fromUrl.companyId;
      const regulatoryDateOfBirthMask = state.shell.config.regulatorySettings ? 
          state.shell.config.regulatorySettings.dobMask : '';
      const patientDto = patientUtils.getPatientDto(patients[0], state.shell.fromUrl.dateOfBirthFormat, regulatoryDateOfBirthMask, false);
      const patientToState = {
        ...patientDto,
        ...{dateOfBirth: utils.formatDateOfBirth(patientDto.dateOfBirth, state.shell.fromUrl.dateOfBirthFormat, regulatoryDateOfBirthMask)}
      }
      
      let actions = [
        setPatientById(patientToState),
        setIsEditPatient(true),
        setErrorConflictAlertVariant(AlertVariant.DANGER),
        setPatientSubmitButtonTranslation(messages.buttonUpdate),
        setErrorGeneralMessageTranslation(messages.errorGeneralMessageEdit),
        checkIfPatientHasConflictsEdit({ patient: patientDto, companyId })
      ];
      const isReadOnlyForm = patientUtils.isIDSPatient(patientDto.type);
      if (isReadOnlyForm) {
        actions = actions.concat([
          setIsReadOnlyForm(true),
          setPatientCancelButtonVariant(ButtonVariant.SECONDARY),
          setFormHeaderTitleTranslation(messages.headerEditDisabled),
          setPatientCancelButtonTranslation(messages.buttonOk)
        ]);
      } else {
        actions.push(setFormHeaderTitleTranslation(messages.headerEdit));
      }
      dispatch(actions);
      break;
    }
    case AT.CHANGE_PATIENT_VALIDATIONS_CONFIG: {
      const newFormFields = patientUtils.getNewFormFieldsRequirements(formState.formFields, payload);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.SET_ZIP_CODE_VISIBILITY: {
      const newFormFields = patientUtils.getNewFormFieldsVisibility(formState.formFields, payload);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.SET_CHART_NUMBER_AVAILABILITY: {
      const newFormFields = patientUtils.getNewFormFieldsAvailability(formState.formFields, FieldName.CHART_NUMBER, payload);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.VALIDATION_SETTINGS_LOADED: {
      const dobFormat = isValidDateFormat(state.shell.fromUrl.dateOfBirthFormat) ? state.shell.fromUrl.dateOfBirthFormat : payload.dobMask ? payload.dobMask : fullDateOfBirthMask;
      const newFormFields = patientUtils.getNewFormFieldsValidations(formState.formFields, payload.maxPatientLastNameLength, dobFormat);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_NEW.SUCCESS:
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT.SUCCESS: {
      dispatch(getActionsWhenPatientHasNoConflicts(formState));
      break;
    }
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_NEW.ERROR:
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT.ERROR: {
      const conflictedPatient = patientUtils.getPatientConflict(payload);
      if (conflictedPatient) {
        const newFormFields = patientUtils.getNewFormFieldsIsShowErrorBorder(
          formState.formFields,
          formState.isReadOnlyForm,
          conflictedPatient
        );
        const actions = [
          setConflictedPatient(conflictedPatient),
          setIsErrorConflictAlert(true),
          setFormFields(newFormFields)
        ];
        if (formState.isEditPatient) {
          actions.push(setIsPatientSubmitDisabled(true));
        } else {
          actions.push(setPatientInConflict(true));
        }
        dispatch(actions);
      }
      break;
    }

    case AT.SAVE_PATIENT.API_REQUEST: {
      dispatch([setIsErrorConflictPopup(false)]);
      break;
    }

    case AT.SAVE_PATIENT.SUCCESS: {
      const patientResponse = payload.data;
      if (patientResponse.statusCode === ApiStatusCodes.CREATED) {
        dispatch([
          setIsErrorConflictAlert(false),
          updateHostPatient(patientResponse.patients && patientResponse.patients[0])
        ]);
      }
      break;
    }

    case AT.SAVE_PATIENT.ERROR:
    case AT.UPDATE_PATIENT.ERROR: {
      const conflictedPatient = patientUtils.getPatientConflict(payload);
      if (conflictedPatient) {
        dispatch([
          setIsErrorGeneralPopup(false),
          setIsErrorConflictAlert(true),
          setIsErrorConflictPopup(true),
          setConflictedPatient(conflictedPatient)
        ]);
      } else {
        dispatch([
          setIsErrorConflictAlert(false),
          setIsErrorConflictPopup(false),
          setConflictedPatient(null),
          setIsErrorGeneralPopup(true)
        ]);
      }
      break;
    }

    case AT.UPDATE_PATIENT.SUCCESS: {
      const patientResponse = payload.data;
      if (patientResponse.statusCode === ApiStatusCodes.SUCCESS) {
        dispatch([
          setIsErrorConflictAlert(false),
          updateHostPatient(patientResponse.patients && patientResponse.patients[0])
        ]);
      }
      break;
    }

    case AT.USER_CLICKED_ON_CONFIRM_ERROR_GENERAL_POPUP: {
      dispatch([setIsErrorGeneralPopup(false)]);
      break;
    }

    case AT.USER_CLICKED_ON_CANCEL_CONFLICT_POPUP: {
      // TBD:
      // if the doctor decide it is isn't the same patient suggested in the dialog,
      // we should act according to the following scenraios:
      const message = getMessageObject();
      message.data = { eventId: rxEventTypes.patientAppCancelConflictPopup, data: null };
      dispatch([setIsErrorConflictPopup(false), sdkSendMessage(message)]);
      break;
    }

    case AT.USER_CLICKED_ON_CANCEL_PATIENT_FORM: {
      dispatch([sdkCloseMe(rxEventTypes.patientAppAddPatient)]);
      break;
    }

    case AT.USER_CLICKED_ON_SUBMIT_PATIENT_FORM: {
      const { form, patientId, companyId, conflictedPatient } = payload;
      const capitalizeValue = text => text.split(' ').map(textUnit => `${textUnit.charAt(0).toUpperCase()}${textUnit.substring(1)}`).join(' ');
      if( form.firstName && form.lastName ) {
         const { firstName, lastName } = { firstName: capitalizeValue(form.firstName), lastName: capitalizeValue(form.lastName) };
         const newForm = { ...form, firstName, lastName, dateOfBirth: form[FieldName.DATE_OF_BIRTH] };
         if (conflictedPatient) {
           dispatch([setIsErrorConflictPopup(true)]);
         } else {
           const dateOfBirthFormat = getDateOfBirthFormat(state);
           dispatch([submitPatientForm(newForm, patientId, companyId, false, dateOfBirthFormat)]);
         }
       }   
      break;
    }

    case AT.USER_CLICKED_ON_CONFIRM_CONFLICT_POPUP: {
      const { conflictedPatient } = payload;
     
      const message = getMessageObject();
      message.data = { eventId: rxEventTypes.patientAppConfirmConflictPopup, data: null };
      dispatch([setIsErrorConflictAlert(false), updateHostPatient(conflictedPatient), sdkSendMessage(message)]);
      break;
    }

    case AT.USER_CHANGED_INPUT_PATIENT: {
      const { form, companyId, isEditPatient, isKeyField, formLastInvalid, invalid } = payload;
      const isFormInvalidChanged = invalid !== formLastInvalid;

      if (invalid) {
        let actions = [];
        if (state.shell.fromUrl.isInlineMode) {
          actions = [updateHostPatient(null)];
        }
        if (isFormInvalidChanged) {
          actions = [setFormLastInvalid(invalid), ...actions];
        }
        if (!form.firstName || !form.lastName) {
          actions = [...actions, ...getActionsWhenPatientHasNoConflicts(formState)];
        }
        dispatch(actions);
        return;
      }

      const dateOfBirthFormat = getDateOfBirthFormat(state);
      if(state.shell.fromUrl.isInlineMode) {
        const patientDto = patientUtils.getPatientDto(form, dateOfBirthFormat);
        dispatch([updateHostPatient(patientDto)]);
      }
      
      if (!isKeyField && !isFormInvalidChanged) {
        return;
      }
      checkIfPatientHasConflicts(dispatch, form, isEditPatient, companyId, dateOfBirthFormat, isFormInvalidChanged, invalid);
      break;
    }

    case AT.USER_CLICKED_ON_AN_ALREADY_PAIRED_PATIENT: {
      const { patient } = payload;
      dispatch([updateHostPatient(patient)]);
      break;
    }

    case AT.UPDATE_HOST_PATIENT: {
      const message = getMessageObject();
      message.data = {
        eventId: rxEventTypes.patientAppUpdateHostPatient,
        data: payload
      };
      dispatch([sdkSendMessage(message)]);
      break;
    }

    case AT.USER_CALL_CLEAR_PATIENT_ACTION: {
      dispatch([setIsClearDataPopup(payload)]);
      break;
    }

    case AT.PARENT_ASK_CREATE_PATIENT: {
      const patientId = state.shell.fromUrl.patientId;
      const companyId = state.shell.fromUrl.companyId;
      const conflictedPatient = formState.conflictedPatient;
      dispatch([userClickedOnSubmitPatientForm({ form: state.form.PatientForm.values, patientId, companyId, conflictedPatient })]);
      break;
    }

    case AT.CALL_SEARCH_PATIENT_TO_PARENT: {
      const message = getMessageObject();
      message.data = {
        eventId: rxEventTypes.patientAppSearchPatient,
      };
      dispatch([sdkSendMessage(message)]);
      break;
    }

    case AT.USER_CONFIRMED_CLEARING_FORM: {
      dispatch([reset(formName), setPatientInConflict(false), updateHostPatient(null)]);
      break;
    }

    case AT.USER_LOADED_EXISTING_PATIENT: {
      const conflictedPatient = formState.conflictedPatient;
      dispatch([setPatientInConflict(false), setConflictedPatient(null), updateHostPatient(conflictedPatient)])
      break;
    }

    default:
    // do nothing
  }
};

export const goThroughOverride = ({ dispatch, getState, action }) => {
  const { type, payload } = action;
  const state = getState();

  switch (type) {
    case AT.APP_READY: {
      // go to server to bring some initial data for the patientManagement
      // goes here
      loadPatient(state.shell.fromUrl, dispatch);
      const message = getMessageObject();
      message.data = {
        eventId: rxEventTypes.patientAppReady,
        data: 'patientAppReady'
      };
      dispatch([sdkSendMessage(message)]);
      break;
    }
    case AT.APP_ERROR: {
      const message = getMessageObject();
      message.data = {
        eventId: rxEventTypes.patientAppError,
        data: payload
      };
      dispatch([sdkSendMessage(message)]);
      break;
    }
    default:
    // do nothing
  }
};

export default createMiddleware({
  feature: featureName,
  goThroughOverride
})(middleware);
