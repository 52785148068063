import React from 'react';
import PropTypes from 'prop-types';

import TranslatedContent from '../../Translations/TranslatedContent';

import { ReactComponent as SearchIcon } from './images/search-icon.svg';
import styles from './NoResults.module.scss';

const NoResults = ({ message }) => (
  <div className={styles.root}>
    <SearchIcon className={styles.icon} />

    <div className={styles.message}>
      <TranslatedContent contentArray={[message]} />
    </div>
  </div>
);

NoResults.propTypes = {
  /**
   * Message to display
   * `react-intl` message descriptor
   */
  message: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string
  })
};

NoResults.defaultProps = {
  message: {}
};

export default NoResults;
