import React from 'react';
import PropTypes from 'prop-types';
import {
  AutoSizer as VirtualizedAutoSizer,
  Column as VirtualizedColumn,
  Table as VirtualizedTable
} from 'react-virtualized';
import 'react-virtualized/styles.css';

import './Table.scss';

const Table = props => {
  const { columnProps, columns, data, tableProps } = props;

  const defaultTableProps = {
    headerHeight: 44,
    rowCount: data.length,
    rowGetter: ({ index }) => data[index],
    rowHeight: 44,
    width: 800,
    height: 600
  };

  return (
    <VirtualizedAutoSizer>
      {({ height, width }) => {
        const mergedProps = {
          ...defaultTableProps,
          ...tableProps
        };

        return (
          <VirtualizedTable
            {...mergedProps}
            height={height || mergedProps.height}
            width={width || mergedProps.width}
          >
            {columns.map(column => {
              return (
                <VirtualizedColumn
                  key={column.dataKey}
                  {...columnProps}
                  {...column}
                />
              );
            })}
          </VirtualizedTable>
        );
      }}
    </VirtualizedAutoSizer>
  );
};

Table.propTypes = {
  /**
   * `react-virtualized` Column props
   * https://github.com/bvaughn/react-virtualized/blob/master/docs/Column.md
   */
  columnProps: PropTypes.object,
  /**
   * Columns definition
   */
  columns: PropTypes.arrayOf(
    /**
     * Column
     */
    PropTypes.shape({
      label: PropTypes.node,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired
    })
  ).isRequired,
  /**
   * Data to render
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * `react-virtualized` Table props
   * https://github.com/bvaughn/react-virtualized/blob/master/docs/Table.md
   */
  tableProps: PropTypes.object
};

Table.defaultProps = {
  columnProps: {},
  tableProps: {}
};

export default Table;
