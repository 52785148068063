import React from 'react';
import PropTypes from 'prop-types';
import TranslatedContent from '../../Translations/TranslatedContent';
import {
    RadioGroup as MuiRadioGroup,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import RadioButton from './RadioButton';
import './RadioGroup.scss';

const classes = {
    root: 'mui-form-control-label-root',
    label: 'mui-form-control-label-label',
}

function RadioGroup(props) {
    const { name, value, readOnly, onChange, touched, error, options, color } = props;
    const isError = !!(touched && error);

    return (
        <FormControl
            component="fieldset"
            error={isError}
        >
            <MuiRadioGroup name={name} value={value} onChange={onChange} row>
                {options.map( (option, idx, propsArray) => {
                    return (
                        <FormControlLabel
                            classes={classes}
                            htmlFor={option.id}
                            id={option.labelId}
                            key={`${option.id}wrapper`}
                            value={option.value}
                            disabled={readOnly}
                            control={<RadioButton
                                id={option.id}
                                color={color}
                            />}
                            checked={(value || Number.isInteger(value)) && value.toString() === option.value.toString()}
                            label={<TranslatedContent contentArray={[option.label]} />}
                        />
                    )
                })}
            </MuiRadioGroup>
            <FormHelperText>{ isError ? <TranslatedContent contentArray={error}/> : '' }</FormHelperText>
        </FormControl>
    );
}



RadioGroup.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  color: PropTypes.string
};

export default RadioGroup;
