import React from 'react';
import Popup from '../Popup';
import { NotificationIcons } from '../Notification/notificationIconsTypes';
import BaseButton from "../BaseButton";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Notification from "../Notification";
import PropTypes from "prop-types";

function QuestionPopup(props) {
  const {
    open,
    cancelText,
    confirmText,
    message,
    title,
    handleCancelClick,
    handleConfirmClick
  } = props;

  const icon = NotificationIcons.Question;

  const footerActions = (
    <>
      <BaseButton onClick={handleCancelClick} color="primary" size="large">
        {cancelText}
      </BaseButton>
      <BaseButton onClick={handleConfirmClick} color="secondary" size="large">
        {confirmText}
      </BaseButton>
    </>
  );

  return (
    <Popup open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent><Notification icon={icon} message={message}/></DialogContent>
        <DialogActions>{footerActions}</DialogActions>
    </Popup>
  );
}

QuestionPopup.propTypes = {
    open: PropTypes.bool,
    cancelText: PropTypes.node,
    confirmText: PropTypes.node,
    message: PropTypes.node,
    title: PropTypes.node,
    handleCancelClick: PropTypes.func,
    handleConfirmClick:PropTypes.func,
}

export default QuestionPopup;
