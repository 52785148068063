import React from 'react';
import { Button } from '@material-ui/core';
import './BaseButton.scss';
import utils from '../../../utils/utils';

export const BaseButtonClasses = {
  root: 'mui-button-root',
  textPrimary: 'mui-button-text-primary',
  textSecondary: 'mui-button-text-secondary',
  sizeLarge: 'mui-button-size-large'
}

const BaseButton = (props) => {
  const { classes } = props;
  let mergedClasses = BaseButtonClasses;

  if (classes) {
    mergedClasses = utils.mergeClassNames(BaseButtonClasses, classes);
  }

  return <Button {...props} classes={mergedClasses}></Button>
}



BaseButton.defaultProps = {
  disableRipple: true,
}

export default BaseButton
