import { get } from 'lodash';

import * as AT from '../../action-types';

const INIT_STATE = {
  data: {},
  message: '',
  origin: '',
  internalEventId: 0,
  result: 0,
  event: 0
};

const { SDK } = AT;
const featureName = SDK;

const sdkReducer = (state = INIT_STATE, action) => {
  const { type, payload, sdkRequest } = action;
  const feature = get(action, 'meta.feature');

  if (feature !== featureName) {
    return state;
  }
  switch (type) {
    case AT.SDK_INIT:
      return {
        ...state,
        message: sdkRequest.message,
        origin: sdkRequest.origin,
        endPoint: sdkRequest.endPoint,
        internalEventId: sdkRequest.internalEventId,
        result: sdkRequest.result,
        event: sdkRequest.event
      };
    case AT.SDK_SEND_MESSAGE:
      return {
        ...state,
        data: payload,
        message: sdkRequest.message,
        origin: sdkRequest.origin,
        result: sdkRequest.result,
        event: sdkRequest.event
      };
    default:
      return state;
  }
};

export default sdkReducer;
