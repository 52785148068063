import { makeStyles } from "@material-ui/core";

export const makeCheckboxStyles = makeStyles({
    root: {
        width: '100%',
        marginLeft: 0,
    },
    label: {
        fontFamily: ['Roboto', 'Helvetica Neue', 'sans-serif'].join(','),
        fontSize: 14
    }
})
