export const blue = {
    '50': '#bfeafb',
    '100':'#b7e7f9',
    '150': '#a6d9eb',
    '200': '#a6e2f9',
    '250': '#98d1e6',
    '300': '#8edaf8',
    '400': '#67ccf2',
    '450': '#5cb7d9',
    '500': '#04adef',
    '550': '#448ebf',
    '600': '#408dc1',
    '650': '#3a88b2',
    '700': '#0c6fb1',
    '750': '#0c68aa',
    '800': '#0067ac',
}

export const grey = {
    '50': '#fdfdfd',
    '100': '#fbfbfb',
    '200': '#f4f4f5',
    '300': '#f3f3f3',
    '350': '#dbdbdb',
    '400': '#ccc',
    '450': '#c8c9cb',
    '500': '#c2c2c4',
    '600': '#afb2b3',
    '700': '#959595',
    '900': '#3e3d40',
}

export const red = {
    '200': '#ffccd4',
    '400': '#ffb8c3',
    '500': '#ff3355',
    '600': '#e70000'
}

export const common = {
    white: '#fff',
    black: '#000',
}

export const paletteLight = {
    blue,
    grey,
    red,
    common,
    primary: {
        lighter: '#f3f3f3',
        light: '#f4f4f5',
        main: '#3e3d40',
        dark: '#c2c2c4',
        darker: '#959595',
        contrastText: '#3e3d40',
    },
    secondary: {
        lighter: '#bfeafb',
        light: '#8edaf8',
        main: '#408dc1',
        dark: '#3a88b2',
        darker: '#0067ac',
        contrastText: '#feffff',
        toString() {return this.main},
    },
    error: {
        main: '#e70000',
        toString() {return this.main}

    },
    warning: {
        light: 'rgba(255, 204, 2, 0.1)',
        main: '#ffcc02',
        toString() {return this.main}

    },
    info: {
        light: '#f5fbfe',
        main: '#9ed9f4',
        toString() {return this.main}

    },
    success: {
        light: 'rgba(33, 212, 9, 0.3)',
        alert: '#21d409',
        main: '#109c6c',
        toString() {return this.main}
    },
    text: {
        primary: '#3e3d40',
        disabled: '#c2c2c4'
    },
    divider: '#c8c9cb',
    background: {
        default: '#ffffff',
    }
}


