import React from 'react';
import Popup from '../Popup';
import { NotificationIcons } from '../Notification/notificationIconsTypes';
import { rxEventTypes } from '../../../redux/core/sdk/sdk.enums';
import { PopupStatus } from '../Popup/PopupEnums';
import BaseButton from "../BaseButton";
import {Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel} from "@material-ui/core";
import Notification from "../Notification";
import { makeCheckboxStyles } from "./WarningPopup.style";
import PropTypes from "prop-types";

function WarningPopup(props) {
  const {
    confirmText,
    message,
    title,
    handleConfirmClick,
    cancelText,
    handleCancelClick,
    shouldEmitToHost,
    checkboxText,
    handleCheckboxChange,
    open,
    icon
  } = props;

  const popupIcon = icon || NotificationIcons.Warning;

  const formControlClasses = makeCheckboxStyles();

  function handleConfirmClickHelper() {
    if (shouldEmitToHost) {
      checkAndEmitDataToHost(false);
    }

    if (typeof handleConfirmClick === 'function') {
      handleConfirmClick();
    }
  }

  function handleCancelClickHelper() {
    if (shouldEmitToHost) {
      checkAndEmitDataToHost(false);
    }

    if (typeof handleCancelClick === 'function') {
      handleCancelClick();
    }
  }

  function handleCheckboxChangeHelper(event) {
    if (typeof handleCheckboxChange === 'function') {
      handleCheckboxChange(event.target.checked);
    }
  }

  function checkAndEmitDataToHost(emitDialogStatus) {
    const docBody = document.querySelector('body');

    const popupEmittedData = {
      eventId: rxEventTypes.patientAppDialog,
      data: 'patientAppDialogOpen'
    };

    if (emitDialogStatus) {
      popupEmittedData.status = PopupStatus.OPEN;
      docBody.classList.add('patient-app--emitting-popup');
    } else {
      popupEmittedData.status = PopupStatus.CLOSED;
      docBody.classList.remove('patient-app--emitting-popup');
    }

    window.parent.postMessage(popupEmittedData, '*');
  }

  const footerActions = (
    <>
      {
        checkboxText && (
            <FormControlLabel
                classes={formControlClasses}
                control={
                  <Checkbox
                      onChange={handleCheckboxChangeHelper}
                      name="checkbox"
                      color="secondary"
                  />
                }
                label={checkboxText}
            />
        )
      }
      {cancelText && (
        <BaseButton onClick={handleCancelClickHelper} color="primary" size="large">
          {cancelText}
        </BaseButton>
    )}
      <BaseButton onClick={handleConfirmClickHelper} color="secondary" size="large">
        {confirmText}
      </BaseButton>

    </>
  );

  if (shouldEmitToHost && open) {
    checkAndEmitDataToHost(true);
  }

  return <Popup open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent><Notification icon={popupIcon} message={message}/></DialogContent>
    <DialogActions>{footerActions}</DialogActions>
  </Popup>;
}

WarningPopup.propTypes = {
  open: PropTypes.bool,
  shouldEmitToHost: PropTypes.bool,
  cancelText: PropTypes.node,
  confirmText: PropTypes.node,
  checkboxText: PropTypes.node,
  message: PropTypes.node,
  title: PropTypes.node,
  handleCancelClick: PropTypes.func,
  handleConfirmClick:PropTypes.func,
  handleCheckboxChange: PropTypes.func
}

export default WarningPopup;
