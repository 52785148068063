import { get } from 'lodash';

import * as AT from '../../action-types';
import { patientIdUrlParamName, baseUrlParamName, companyIdUrlParamName, 
         patientTypeParamName, isInlineModeParamName, isMidcSourceUrlParamName,
         dateOfBirthFormatUrlParamName, shouldHideGenderAndDob } from '../../../consts';

const INIT_STATE = {
  config: {},
  fromUrl: {
    endpoint: '',
    companyId: '',
    patientId: '',
    patientType: '',
    isInlineMode: false,
    dateOfBirthFormat: '',
    isMidcSource: true
  },
  showLoader: true
};

const featureName = AT.SHELL;

const appConfigReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  const feature = get(action, 'meta.feature');

  if (feature !== featureName) {
    return state;
  }

  switch (type) {
    case AT.SET_APP_CONFIG:
      return {
        ...state,
        config: payload
      };
    case AT.SET_URL_PARAMS:
      return {
        ...state,
        fromUrl: {
          endpoint: payload[baseUrlParamName],
          patientId: payload[patientIdUrlParamName],
          companyId: payload[companyIdUrlParamName],
          patientType: payload[patientTypeParamName],
          isInlineMode: payload[isInlineModeParamName] && payload[isInlineModeParamName].toLowerCase() === 'true',
          isMidcSource: !!payload[isMidcSourceUrlParamName] && payload[isMidcSourceUrlParamName].toLowerCase() === 'true',
          dateOfBirthFormat: payload[dateOfBirthFormatUrlParamName] ? payload[dateOfBirthFormatUrlParamName].toUpperCase() : '',
          shouldHideGenderAndDob: payload[shouldHideGenderAndDob] && payload[shouldHideGenderAndDob].toLowerCase() === 'true'
        }
      };
    case AT.SET_SHOW_LOADER:
      return { ...state, showLoader: payload };
    default:
      return state;
  }
};

export default appConfigReducer;
