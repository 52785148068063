import { Dialog, withStyles } from '@material-ui/core';

const createStyles = ({palette}) => ({
    paperScrollPaper: {
        maxHeight: '100%',
    },
    paper: {
        margin: '0',
        backgroundColor: palette.grey["200"],
    },
    paperWidthMd: {
        minWidth: '800px',
    }
})

const Popup = withStyles(createStyles)(Dialog);

Popup.defaultProps = {
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    BackdropProps: { invisible: true },
    maxWidth: 'md',
}

export default Popup;
