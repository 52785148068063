import createMiddleware from '../utils/middleware-helper';

const middleware = () => {};

const nextOverride = ({ next, action }) => {
  if (Array.isArray(action)) {
    action.forEach(_action => next(_action));
  } else {
    next(action);
  }
};

export default createMiddleware({
  feature: '',
  nextOverride
})(middleware);
