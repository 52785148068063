import { get } from 'lodash';
import * as AT from '../../action-types';

const INIT_STATE = {
  patientsList: [],
  conflictedPatient: null
};

const featureName = AT.PATIENT_SEARCH;

const reducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  const feature = get(action, 'meta.feature');

  if (feature !== featureName) {
    return state;
  }

  switch (type) {
    case AT.SET_PATIENTS:
      return { ...state, patientsList: payload };
    case AT.SET_CONFLICTED_PATIENT:
      return { ...state, conflictedPatient: payload };
    default:
      return state;
  }
};

export default reducer;
