import React from 'react';
import uuid from 'uuid';
import { findKey } from 'lodash';
import { PatientType, GenderType } from '../../utils/enums';
import { patientSearchTranslatedStrings as messages } from '../../translations/PatientSearchTranslations';
import TranslatedContent from '../Translations/TranslatedContent';
import { ReactComponent as IDSIcon } from '../../assets/images/IDS_icon.svg';
import { ReactComponent as IteroIcon } from '../../assets/images/itero_icon.svg';
import { ReactComponent as PairedIcon } from '../../assets/images/paired_icon.svg';
import utils from '../../utils/utils';
import styles from './PatientSearch.module.scss';

export const mapPatientTypeToIcon = type => {
  switch (type) {
    case PatientType.ITERO:
      return IteroIcon;

    case PatientType.IDS:
      return IDSIcon;

    case PatientType.ITERO_IDS_PAIR:
      return PairedIcon;

    default:
      return null;
  }
};

export const mapPatientsToTable = (patients = [], state) => {
  const mappedPatients = patients.map(patient => {
    const { gender, type } = patient;
    const Icon = mapPatientTypeToIcon(type);
    const genderTypeKey = findKey(GenderType, type => type.value === gender);
    const dateOfBirthMask = state.shell.fromUrl.dateOfBirthFormat;
    const regulatoryDateOfBirthMask = state.shell.config.regulatorySettings ? 
        state.shell.config.regulatorySettings.dobMask :
        undefined;
    const genderText = genderTypeKey ? GenderType[genderTypeKey].text : '';
    return {
      ...patient,
      id: uuid(),
      icon: Icon && <Icon className={styles.rowIconSvg} />,
      genderText: genderText,
      dateOfBirth: utils.formatDateOfBirth(patient.dateOfBirth, dateOfBirthMask, regulatoryDateOfBirthMask)
    };
  });

  return mappedPatients;
};

export const doctorTableProps = {
  searchColumnKeys: ['fullName', 'chartNumber'],
  sortColumnKeys: ['fullName', 'chartNumber'],
  tableColumns: [
    {
      dataKey: 'fullName',
      label: <TranslatedContent contentArray={[messages.fullName]} />,
      width: 246
    },
    {
      dataKey: 'genderText',
      label: <TranslatedContent contentArray={[messages.gender]} />,
      width: 102
    },
    {
      dataKey: 'dateOfBirth',
      label: <TranslatedContent contentArray={[messages.dateOfBirth]} />,
      width: 188
    },
    {
      dataKey: 'chartNumber',
      label: <TranslatedContent contentArray={[messages.chartNumber]} />,
      width: 202
    }
  ]
};

export const pairedDoctorTableProps = {
  searchColumnKeys: ['fullName', 'alignPatientId', 'chartNumber'],
  sortColumnKeys: ['fullName', 'chartNumber', 'alignPatientId'],
  tableColumns: [
    // { dataKey: 'icon', width: 44 },
    {
      dataKey: 'fullName',
      label: <TranslatedContent contentArray={[messages.fullName]} />,
      width: 256
    },
    {
      dataKey: 'genderText',
      label: <TranslatedContent contentArray={[messages.gender]} />,
      width: 96
    },
    {
      dataKey: 'dateOfBirth',
      label: <TranslatedContent contentArray={[messages.dateOfBirth]} />,
      width: 128
    },
    // {
    //   dataKey: 'alignPatientId',
    //   label: <TranslatedContent contentArray={[messages.alignPatientId]} />,
    //   width: 128
    // },
    {
      dataKey: 'chartNumber',
      label: <TranslatedContent contentArray={[messages.chartNumber]} />,
      width: 128
    },
    // {
    //   dataKey: 'zipCode',
    //   label: <TranslatedContent contentArray={[messages.zipCode]} />,
    //   width: 128
    // }
  ]
};

export const getConflictMessage = (conflictedPatient) => {
  return (
      <>
        <TranslatedContent contentArray={[messages.pairMessage]} />
        <br />
        <TranslatedContent contentArray={[conflictedPatient.fullName]} />
        &nbsp;
        <TranslatedContent contentArray={[conflictedPatient.gender ? messages.female : messages.male]} />
        &nbsp;
        <TranslatedContent contentArray={[conflictedPatient.dateOfBirth]} />
        &nbsp;
        <TranslatedContent contentArray={[messages.chartNumber, conflictedPatient.chartNumber]} />
        <br />
        <br />
        <TranslatedContent contentArray={[messages.pairMessageQuestion]} />
      </>
  );
}
