import * as AT from '../../action-types';
import { urls } from '../../../consts';

export const appReady = () => ({
  type: AT.APP_READY,
  payload: {},
  meta: {
    feature: AT.SHELL
  }
});

export const appError = error => ({
  type: AT.APP_ERROR,
  payload: error,
  meta: {
    feature: AT.SHELL
  }
});

export const appInit = urlParams => ({
  type: AT.APP_INIT,
  payload: urlParams,
  meta: {
    feature: AT.SHELL
  }
});


export const getAppConfig = ({ companyId, setLoader = true }) => ({
  type: AT.GET_APP_CONFIG.API_REQUEST,
  payload: { companyId },
  meta: {
    withCredentials: true,
    feature: AT.SHELL,
    sourceAction: AT.GET_APP_CONFIG,
    url: urls.patientAppConfigUrl,
    method: 'GET',
    setLoader
  }
});

export const setAppConfig = config => ({
  type: AT.SET_APP_CONFIG,
  payload: config,
  meta: {
    feature: AT.SHELL
  }
});

export const changeZipCodeVisibility = config => ({
  type: AT.SET_ZIP_CODE_VISIBILITY,
  payload: config,
  meta: {
    feature: AT.SHELL
  }
});

export const setUrlParams = urlParams => ({
  type: AT.SET_URL_PARAMS,
  payload: urlParams,
  meta: {
    feature: AT.SHELL
  }
});

export const setShowLoader = shouldShowLoader => ({
  type: AT.SET_SHOW_LOADER,
  payload: shouldShowLoader,
  meta: {
    feature: AT.SHELL
  }
});
