import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Header.module.scss';

const Header = props => {
  const { actions, className: classNameProp, style, title } = props;
  const className = classNames(classNameProp, styles.root);

  return (
    <header className={className} style={style}>
      <h1 className={styles.title}>{title}</h1>
      {actions && <div>{actions}</div>}
    </header>
  );
};

Header.propTypes = {
  /**
   * Actions to display
   */
  actions: PropTypes.node,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Style
   */
  style: PropTypes.object,
  /**
   * Title to display
   */
  title: PropTypes.node
};

Header.defaultProps = {
  style: null,
  title: ''
};

export default Header;
