import { deburr, isObject } from 'lodash';
import { fullDateOfBirthMask } from '../consts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const printToConsoleLog = false;

const isString = object => Object.prototype.toString.call(object) === '[object String]';

const formatDateString = dateStr => (dateStr ? dateStr.split('T').shift() : null);

const isGuid = stringToTest => {
  if (!stringToTest) {
    return false;
  }
  if (stringToTest[0] === '{') {
    stringToTest = stringToTest.substring(1, stringToTest.length - 1);
  }
  const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(stringToTest);
};

const isValidUrl = url => {
  const pattern = new RegExp(
    '^((https|http)+:\\/\\/)+' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(url);
};

const isTranslationObject = object =>
  Object.prototype.toString.call(object) === '[object Object]' && object.hasOwnProperty('id');

const log = value => {
  if (printToConsoleLog) {
    if (isObject(value)) {
      console.log(JSON.stringify(value));
    } else {
      console.log(value);
    }
  }
};

const logMessage = (message, value) => {
  if(printToConsoleLog)
    console.log(JSON.stringify({ message, value }));
};

const group = (value) => {
  if(printToConsoleLog)
    console.group(value);
};

const isAsianLanguage = str =>
  !!str.match(
    /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf-\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/
  );

const normalizeString = str => deburr(str).toLowerCase();

const capitalizeString = str => str.replace(/(^\w| \w)/g, match => match.toUpperCase());
const stringToWords = str => str.trim().split(/ +/);

const isStringContainsAny = (str, subStringArr) => {
  if (!subStringArr || !str) {
    return '';
  }
  return subStringArr.some(item => {
    return str.indexOf(item) >= 0;
  });
};

const arrayToQueryString = (arr, stringToConcatenate) => {
  if (!arr || !stringToConcatenate) {
    return '';
  }
  return arr.reduce((query, value) => {
    return `${query}&${stringToConcatenate}=${value}`;
  }, '');
};

const formatDateOfBirth = (dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask) => {
  if (!dateOfBirth) {
    return dateOfBirth;
  }

  if (!dateOfBirthFormat) {
    dateOfBirthFormat = regulatoryDateOfBirthMask ? regulatoryDateOfBirthMask : fullDateOfBirthMask;
  }

  if( dateOfBirth.indexOf('T') > 0) {
    dateOfBirth = formatDateString(dateOfBirth);
  }
  
  dateOfBirth = getDayJs(dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask);

  return dateOfBirth.format(dateOfBirthFormat);
};

const getFullDateOfBirth = (dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask) => {
  if (!dateOfBirth) {
    return dateOfBirth;
  }
  const result = getDayJs(dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask);
  return result.utc(true).format();
};

const getShortDateOfBirth = (dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask) => {
  if (!dateOfBirth) {
    return dateOfBirth;
  }
  const result = getDayJs(dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask);
  return result.format('YYYY-MM-DD');
};

function getDayJs(dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask) {
  if (regulatoryDateOfBirthMask) {
    const formattedDateOfBirth = dayjs(dateOfBirth, regulatoryDateOfBirthMask);
    if (formattedDateOfBirth.isValid()) {
      return formattedDateOfBirth;
    }
  }

  const formattedDateOfBirth = dayjs(dateOfBirth, dateOfBirthFormat);

  return formattedDateOfBirth.isValid() ? formattedDateOfBirth : dayjs(dateOfBirth);
}

const mergeClassNames = (classes, newClasses) => {
  const mergedClasses = { ...classes, ...newClasses };

  Object.entries(classes).forEach(([key, value]) => {
    if (newClasses.hasOwnProperty(key)) {
      mergedClasses[key] = `${value} ${mergedClasses[key]}`;
    }
  });

  return mergedClasses;
};

export default {
  isString,
  formatDateString,
  isGuid,
  isValidUrl,
  isTranslationObject,
  normalizeString,
  log,
  logMessage,
  group,
  stringToWords,
  isStringContainsAny,
  arrayToQueryString,
  mergeClassNames,
  formatDateOfBirth,
  getFullDateOfBirth,
  capitalizeString,
  isAsianLanguage,
  getShortDateOfBirth
};
