import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';

import PatientManagementForm from './components/PatientManagementForm';
import PatientInlineForm from './components/PatientManagementInlineForm';
import PatientSearchContainer from './components/PatientSearch';
import { appInit } from './redux/core/shell/shell.actions';
import { langCodeParamName, defaultLangCode } from './consts';
// Our translated strings and per language locale data
import { translations, localeData } from './translations/translations_combined';
import styles from './App.module.scss';
import PatientAppSpinner from './components/UI/Spinner/Spinner';

import { ThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import {paletteLight} from "./components/UI/material-customization/palette";


const newHistory = createBrowserHistory();

// i18n
addLocaleData(localeData);
const locale = queryString.parse(window.location.search.toLowerCase())[langCodeParamName] || defaultLangCode;
const messages = translations[locale] || translations.en;

// Material UI theme
const theme = createMuiTheme({
  palette: paletteLight,
  overrides: {
    MuiInputLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '16px'
      }
    },
    MuiFormLabel: {
      colorSecondary: {
        color: '#3DAEF2'
      },
      root: {
        color: '#3e3d40'
      },
      asterisk: {
        color: '#FF0000'
      },
    }
  },
  typography: {
    fontFamily: [
      'Avenir-Roman',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  }
});

class App extends Component {
  componentDidMount() {
    const urlParams = queryString.parse(window.location.search);
    this.props.appInit(urlParams);
  }

  getForm(isInlineMode) {
    return isInlineMode ? this.getInlineForm() : this.getPopUpForm();
  }

  getInlineForm() {
    return <PatientInlineForm></PatientInlineForm>;
  }

  getPopUpForm() {
    return (
        <>
          <PatientAppSpinner showSpinner={this.props.showLoader} />
          <Router history={newHistory}>
            <div>
              <Route path="/patient" exact component={PatientManagementForm} />
              <Route path="/patient-search" exact component={PatientSearchContainer} />
            </div>
          </Router>
        </>
    );
  }

  render() {
    return (
        <IntlProvider locale={locale} key={locale} messages={messages}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <div className={styles.App}>{this.getForm(this.props.isInlineMode)}</div>
            </ThemeProvider>
          </StylesProvider>
        </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  const { shell } = state;
  return {
    isInlineMode: shell.fromUrl.isInlineMode,
    endpoint: shell.fromUrl.endpoint,
    showLoader: shell.showLoader
  };
};

const mapDispatchToProps = {
  appInit
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
