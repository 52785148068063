// url parameters
export const langCodeParamName = 'lang-code';
export const baseUrlParamName = 'endpoint';
export const patientIdUrlParamName = 'id';
export const companyIdUrlParamName = 'companyid';
export const doctorIdUrlParamName = 'doctorid';
export const patientTypeParamName = 'type';
export const isInlineModeParamName = 'isInlineMode';
export const isMidcSourceUrlParamName = 'isMidcSource';
export const dateOfBirthFormatUrlParamName = 'dateOfBirthFormat';
export const defaultLangCode = 'en-us';
export const fullDateOfBirthMask = 'YYYY/MM/DD';
export const monthsMask = 'MM';
export const daysMask = 'DD';
export const shouldHideGenderAndDob = 'shouldHideGenderAndDob';


// api
export const urls = {
  patientSaveUrl: '/patients/save',
  patientUpdateByIdUrl: '/patients/update',
  patientsGetAllUrl: '/patients/',
  patientsGetByIdUrl: '/patients/get',
  patientAppConfigUrl: '/patientappconfig/get'
};