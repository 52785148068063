import React from "react";
import BaseButton from "../BaseButton";
import './IconButton.scss';

const IconClasses = {
    root: "mui-icon-button-root"
}

export const IconButton = (props) => {
    return <BaseButton {...props} color="secondary" classes={IconClasses}></BaseButton>
}