import { sdkRawMessage } from './../core/sdk/sdk.enums';
export const getMessageObject = () => ({
  ...sdkRawMessage,
  data: {
    eventId: null,
    data: null
  }
});

export const isValidDateFormat = (format) => {
  if (!format) { return false; };
  const lowerCaseFormat = format.toLowerCase();
  return lowerCaseFormat.includes('yyyy') || lowerCaseFormat.includes('mm') || lowerCaseFormat.includes('dd');
}
