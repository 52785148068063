import * as AT from '../../action-types';

const { SDK } = AT;

export const sdkInit = ({ sdkRequest }) => ({
  type: AT.SDK_INIT,
  sdkRequest,
  meta: {
    feature: SDK
  }
});

export const sdkSendMessage = ({ sdkRequest, data }) => ({
  type: AT.SDK_SEND_MESSAGE,
  payload: data,
  sdkRequest,
  meta: {
    feature: SDK
  }
});

export const sdkCloseMe = callerId => ({
  type: AT.SDK_CLOSE_ME,
  payload: callerId,
  meta: {
    feature: SDK
  }
});
