export const doctorIdLocalStorageKey = 'doctorId';
export const getAppConfigKey = () => `patientAppConfig(${getDoctorId()})`;

const updatePatientAppConfig = config => {
    const patientAppConfig = getPatientAppConfig();
    localStorage.setItem(getAppConfigKey(), JSON.stringify({ ...patientAppConfig, ...config }));
}

const getPatientAppConfig = () => {
    const localPatientAppConfig = localStorage.getItem(getAppConfigKey());
    if (!localPatientAppConfig) {
        return {};
    }

    try {
        return JSON.parse(localPatientAppConfig);
    } catch {
        localStorage.setItem(getAppConfigKey(), "{}");
        return {};
    }
}

const getDoctorId = () => {
    return localStorage.getItem(doctorIdLocalStorageKey) || '';
}

const updateDoctorId = doctorId => {
    localStorage.setItem(doctorIdLocalStorageKey, doctorId || '');
}

export default {
    getPatientAppConfig,
    updatePatientAppConfig,
    getDoctorId,
    updateDoctorId
}