import React from 'react';

import styles from './LinkLabel.module.scss';

const LinkLabel = props => {
    return (
        <span className={styles.linkLabel} onClick={props.onClick}>{props.children}</span>
    );
};

export default LinkLabel;