import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Footer.module.scss';

const Footer = props => {
  const { actions, className: classNameProp } = props;

  const className = classNames(classNameProp, styles.root);

  return (
    <div className={className}>
      {actions}
    </div>
  );
};

Footer.propTypes = {
  /**
   * Actions to display
   */
  actions: PropTypes.node
};

Footer.defaultProps = {
  actions: null
};

export default Footer;
